import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationExtDto, ProjectDraftDto, ProjectDto } from '@app-com/api/models';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { ViewApplicationDrafts } from '@app-pot/features/grant-application/models';
import { ApplicationStatus } from '@app-pot/features/grant-application/models/enums';
import { FetchAcceptedApplications } from '@app-pot/store/actions/applications.action';
import {
  LoadAllCapitalAssetTypes,
  LoadFunctionalCategoryTypes,
  LoadPrimaryOutcomes,
} from '@app-pot/store/actions/lookup-value.action';
import { ApplicationsState } from '@app-pot/store/state/applications.state';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { CapitalAssetTypeLV, LookupValue, LookupValueState } from '@app-pot/store/state/lookup-value.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest, delay, filter, tap } from 'rxjs';
import { ActiveTabService } from '../active-tab.service';

@Component({
  selector: 'app-accepted-applications',
  templateUrl: './accepted-applications.component.html',
  styleUrls: ['../common-tab-style.scss'],
})
export class AcceptedApplicationsComponent {
  @Select(ApplicationsState.fetchAcceptedApplications) fetchApplications$: Observable<ApplicationExtDto[]>;
  @Select(LookupValueState.getPrimaryOutcomes) primaryOutcomes$: Observable<LookupValue[]>;
  @Select(LookupValueState.getFunctionalCategoryTypes) functionalCategoryTypes$: Observable<LookupValue[]>;
  @Select(LookupValueState.getAllCapitalAssetTypes) allCapitalAssetTypes$: Observable<CapitalAssetTypeLV[]>;
  @Output() filterTableData: EventEmitter<string> = new EventEmitter<string>();

  pageId = 'ACCEPTED_APPLICATIONS';
  applications: ViewApplicationDrafts[];
  filteredApplications: ViewApplicationDrafts[];
  expandableRows: true;
  isAlive = true;
  allCapitalAssetTypesCache: CapitalAssetTypeLV[] = [];
  sub = new Subscription();
  organizationIdSelected: number;
  CommUtilsService = CommUtilsService;
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private activeTabService: ActiveTabService,
  ) {
    this.sub.add(
      this.store.select(CurrentContextState.getCurrentOrganizationId).subscribe((currentOrganizationId) => {
        this.organizationIdSelected = currentOrganizationId;
      }),
    );
  }

  ngOnInit(): void {
    this.store.dispatch(new FetchAcceptedApplications());
    this.store.dispatch(new LoadPrimaryOutcomes());
    this.store.dispatch(new LoadFunctionalCategoryTypes());
    this.store.dispatch(new LoadAllCapitalAssetTypes());

    this.sub.add(
      this.sub.add(
        combineLatest([this.fetchApplications$, this.primaryOutcomes$, this.functionalCategoryTypes$])
          .pipe(
            tap(([applications, primaryOutcomesLookupValues, functionalCategoryTypeValues]) => {
              this.applications = applications.map((application) =>
                this.toViewApplications(application, primaryOutcomesLookupValues, functionalCategoryTypeValues),
              );
              this.applications.sort(
                // use submitted Date
                (a: ViewApplicationDrafts, b: ViewApplicationDrafts) =>
                  (new Date(a.submittedDate!) > new Date(b.submittedDate!) ? 1 : -1) * -1,
              );
              this.filteredApplications = this.applications;
            }),
          )
          .subscribe(),
      ),
    );

    this.sub.add(
      this.allCapitalAssetTypes$.subscribe((data) => {
        this.allCapitalAssetTypesCache = data ?? [];
      }),
    );
    this.sub.add(
      this.activatedRoute.url.subscribe((segments) => {
        this.activeTabService.setActiveTab(segments[0].toString());
      }),
    );
    this.sub.add(
      this.activeTabService
        .getActiveTabSearch()
        .pipe(
          delay(500),
          filter((search) => search !== undefined),
        )
        .subscribe((search) => {
          if (search !== undefined) {
            this.searchTableData(search);
          }
        }),
    );
  }

  public searchTableData(param: string): void {
    const searchTerm = param.toLowerCase();

    if (searchTerm.length > 0) {
      const filteredData = this.filteredApplications.filter((application) => {
        const applicationsSearch =
          application.primaryFunctionalCategoryTitle?.toLowerCase()?.includes(searchTerm) ||
          application.appNumber?.toLowerCase().includes(searchTerm) ||
          application.name.toLowerCase().includes(searchTerm) ||
          application.primaryOutcomeTitle.toLowerCase().includes(searchTerm);

        const projectsSearch = application.projects?.some(
          (project) =>
            project.name.toLowerCase().includes(searchTerm) ||
            project.idTxt?.toLowerCase().includes(searchTerm) ||
            this.getCapitalAssetTypeTitle(project.capitalAssetTypeId).toLowerCase().includes(searchTerm),
        );

        return applicationsSearch || projectsSearch;
      });

      this.filteredApplications = filteredData;
    } else {
      this.filteredApplications = this.applications;
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
  }

  toViewApplications(
    application: ApplicationExtDto,
    primaryOutcomesLookupValues: LookupValue[],
    functionalCategoryTypeValues: LookupValue[],
  ): ViewApplicationDrafts {
    const lookupValuePrimaryOutcome = primaryOutcomesLookupValues.find(
      (value) => value.id === application.primaryOutcomeId,
    );
    const primaryFunctionalCategoryId =
      application.functionalCategories?.find((x) => x.isPrimary === true)?.functionalCategoryTypeId || 0;
    const lookupValuePrimaryFunctionalCategory = functionalCategoryTypeValues.find(
      (value) => value.id === primaryFunctionalCategoryId,
    );
    const uiProjects: ProjectDto[] = application?.projects ?? [];
    const uiData = {
      id: application.id || 0,
      name: application.name,
      primaryFunctionalCategoryTitle: lookupValuePrimaryFunctionalCategory
        ? lookupValuePrimaryFunctionalCategory.title
        : '',
      primaryOutcomeTitle: lookupValuePrimaryOutcome ? lookupValuePrimaryOutcome.title : '',
      createdAt: application.createdAt ?? '',
      projects: uiProjects,
      appNumber: application.idTxt, // submit generated, APP-#####
      // submittedDate: application.applicationRecord.certifiedAt,  // it current has a wrong encode of \"\"date string\"\"
      acceptedDate: application.acceptedAt,
      //status: application.status,
    };
    return uiData;
  }

  onAppInfoClick(applicationId: number) {
    const url =
      '/application-summary/' + ApplicationStatus.Accepted + '/' + this.organizationIdSelected + '/' + applicationId;
    const width = Math.min(1024, window.screen.availWidth - 150);
    const height = Math.min(1439, window.screen.availHeight - 100);
    window.open(
      url,
      '_blank',
      `toolbar=yes, scrollbars=yes, resizable=yes,
       width=${width}, height=${height}`,
    );
  }

  // sort handler
  handleSort(event: Event) {
    if (!(event instanceof CustomEvent)) return;
    const { sortBy, sortDir } = event.detail;
    if (sortBy === 'acceptedDate') {
      // submitted Date
      this.filteredApplications.sort(
        (a: ViewApplicationDrafts, b: ViewApplicationDrafts) =>
          // @ts-expect-error @typescript-eslint/ban-ts-comment
          (new Date(a[sortBy]) > new Date(b[sortBy]) ? 1 : -1) * sortDir,
      );
    } else {
      this.filteredApplications.sort(
        (a: ViewApplicationDrafts, b: ViewApplicationDrafts) =>
          // @ts-expect-error @typescript-eslint/ban-ts-comment
          (a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1) * sortDir,
      );
    }
  }

  public getCapitalAssetTypeTitle(id: number | undefined): string {
    if (!id || id <= 0) return 'invalid id';

    const title = this.allCapitalAssetTypesCache.find((item) => item.id === id)?.title ?? '' + id;
    return title;
  }
  getAdditionalCapitalAssetTypeTitle(project: ProjectDraftDto) {
    // @ts-expect-error @typescript-eslint/ban-ts-comment
    if (!project['additionalCapitalAssetTypes'] || project['additionalCapitalAssetTypes'].length == 0) {
      return '-';
    }
    // @ts-expect-error @typescript-eslint/ban-ts-comment
    return (project['additionalCapitalAssetTypes'] as CapitalAssetTypeLV[])
      .map((cat: CapitalAssetTypeLV) => {
        return cat.title.replace(/\([^)]*\)/g, '').trim();
      })
      .join(', ');
  }

  toggleApplicationDetails(applicationId: number) {
    this.filteredApplications = this.filteredApplications.map((_) => {
      if (_.id === applicationId) return { ..._, detailsShown: !_.detailsShown };
      return _;
    });
  }
}
