<goa-block class="map-heading" direction="column" mt="xl" gap="s">
  <h3>Project location</h3>
  <p>LGFF requires local governments to indicate a location for each individual project.</p>
</goa-block>
<div class="map-container" *ngIf="isMapVisible" [id]="locationStartSpotId">
  <goa-block gap="none" direction="column">
    <goa-block gap="none" class="search-heading" direction="column">
      <p>
        Search for a location or click on the map to add a marker that best represents the location of your project.
      </p>
      <p>
        If your project can’t be mapped (e.g., a plan or a study), please select your administration building location.
      </p>
    </goa-block>
    <goa-spacer vSpacing="l"></goa-spacer>

    <goa-block gap="none" class="search-block" direction="column">
      <h3>Search</h3>
      <goa-input #mapSearchField goaValue name="mapSearch" width="400px"></goa-input>
      <span>Search by address, city, postal code or location name.</span>
    </goa-block>
    <goa-block gap="none" direction="column" class="location-confirmation-block" *ngIf="locationConfirmation">
      <goa-divider *ngIf="tempselectedPlaceCoord || locationConfirmation" mt="xl" mb="xl"></goa-divider>

      <h3 *ngIf="tempselectedPlaceCoord">Would you like to select this marker location?</h3>
      <p class="formatted-address">{{ tempselectedPlace || '' }}</p>
      <p class="coordinates" *ngIf="tempselectedPlaceCoord">
        {{ tempselectedPlaceCoord.lat() }}, {{ tempselectedPlaceCoord.lng() }}
      </p>
      <goa-button type="secondary" (_click)="selectLocation()" size="compact">Select location</goa-button>
    </goa-block>

    <div class="project-location">
      <goa-divider *ngIf="locationConfirmed && formMode === 'ADD'" mb="xl" mt="xl"></goa-divider>
      <h3 *ngIf="locationConfirmed && formMode === 'ADD'">Selected location</h3>
      <goa-block gap="none" direction="column" *ngIf="locationConfirmed && formMode === 'ADD'">
        <p>{{ selectedPlace || '' }}</p>
        <p>{{ selectedPlaceCoord && selectedPlaceCoord.lat() }}, {{ selectedPlaceCoord?.lng() }}</p>
        <span
          ><goa-icon type="information-circle" size="small"></goa-icon> Want to change this location? Search or click on
          the map to add a new marker.</span
        >
      </goa-block>

      <goa-divider *ngIf="formMode === 'EDIT'" mb="xl" mt="xl"></goa-divider>
      <h3 *ngIf="formMode === 'EDIT'">Selected location</h3>
      <goa-block gap="none" direction="column" *ngIf="formMode === 'EDIT'">
        <p *ngIf="!locationConfirmed">{{ locationDetails?.address || '' }}</p>
        <p *ngIf="!locationConfirmed">{{ locationDetails?.coord?.lat }} {{ locationDetails?.coord?.lng }}</p>
        <p *ngIf="locationConfirmed">{{ selectedPlace || '' }}</p>
        <p *ngIf="locationConfirmed">{{ selectedPlaceCoord?.lat() }}, {{ selectedPlaceCoord?.lng() }}</p>
        <goa-block gap="none" mt="none" mb="none">
          <goa-icon type="information-circle" size="small"></goa-icon>
          <span>Want to change this location? Search or click on the map to add a new marker.</span>
        </goa-block>
      </goa-block>
    </div>
  </goa-block>

  <google-map *ngIf="isMapVisible" width="null" height="null" [options]="mapOptions"> </google-map>
</div>

<app-dialog [isOpen]="showDialog" (closeDialog)="closeDialog($event)"> </app-dialog>
