import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';
import { LgffGoaModalComponent } from './components/lgff-goa-modal/lgff-goa-modal.component';
import { LgffGoaTablePaginationComponent } from './components/lgff-goa-table-pagination/lgff-goa-table-pagination.component';
import { LgffGoaTableComponent } from './components/lgff-goa-table/lgff-goa-table.component';
import '@abgov/web-components';
import { AngularComponentsModule } from '@abgov/angular-components';
@NgModule({
  declarations: [ResourcePipe, LgffGoaTableComponent, LgffGoaTablePaginationComponent, LgffGoaModalComponent],
  imports: [CommonModule, ReactiveFormsModule, AngularComponentsModule],
  exports: [ResourcePipe, LgffGoaTableComponent, LgffGoaTablePaginationComponent, LgffGoaModalComponent],
  providers: [ResourcePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProjectCommonModule {}
