<div id="app-header-1">
  <div style="padding: 16px 0px; display: flex">
    <div class="align-logo">
      <a target="_blank" href="https://www.alberta.ca/">
        <app-svg-alberta-logo-header></app-svg-alberta-logo-header>
      </a>
    </div>
    <div>
      <label id="logo-label"><abbr id="logo-label-abbr" title="{{ 'LGFF' | res: 'COMMON' }}">LGFF</abbr> Capital</label>
    </div>
  </div>

  <div class="userInfo">
    <div class="userOrganizationfield">
      <goa-dropdown
        width="100%"
        goaValue
        name="goa-ddl-organizations"
        native="true"
        [matTooltip]="organizationIdSelectedTitle"
        (_change)="onChangeUserOrganizationList($event)"
        id="user-organizations-goa-dropdown"
        [value]="organizationIdSelected"
      >
        <goa-dropdown-item *ngFor="let org of organizations" [value]="org.id" [label]="org.title" goaValue>
        </goa-dropdown-item>
      </goa-dropdown>
      <label
        id="user-organizations-label-full"
        *ngIf="organizations && organizations.length == 1"
        class="org-full-text"
        >{{ singleOrganizationTitle }}</label
      >
      <label
        id="user-organizations-label-short"
        [matTooltip]="singleOrganizationTitle"
        *ngIf="organizations && organizations.length == 1"
        class="org-short-text"
        >{{ singleOrganizationTitle }}</label
      >
    </div>
    <goa-app-header-menu
      heading="{{ user?.name }}"
      leadingicon="person-circle"
      *ngIf="user$ | async as user"
      id="app-header-1-user-menu"
    >
      <a
        id="app-header-1-user-menu-ma-connect"
        target="_blank"
        href="{{ maConnectDashboardLink }}"
        *ngIf="user$ | async as user"
        >{{ 'maConnect' | res: pageId }}<goa-spacer hSpacing="xs"></goa-spacer><goa-icon type="open"></goa-icon
      ></a>
      <a id="app-header-1-user-menu-logout" href="/logout" *ngIf="user$ | async as user">{{
        'signout' | res: pageId
      }}</a>
    </goa-app-header-menu>
  </div>
</div>

<goa-divider></goa-divider>

<goa-app-header id="app-header-2" maxContentWidth="100%" class="alignHeader2">
  <a
    id="app-header-2-a-home"
    class="nav-item-margin-right"
    [ngClass]="clickedMenu == 'home' ? 'hereClicked' : 'hereNotClicked'"
    href="/home"
    (click)="clickedMenuItem('home', false)"
    >{{ 'home' | res: pageId }}</a
  >

  <!-- [ngClass]="clickedMenu.indexOf('view-applications') >= 0 ? 'hereClicked' : 'hereNotClicked'" -->
  <goa-app-header-menu
    heading="{{ 'applications' | res: pageId }}"
    id="app-header-2-a-applications"
    class="nav-item-margin-right"
  >
    <a
      id="app-header-2-a-applications-drafts"
      routerLink="/view-applications/drafts"
      (click)="clickedMenuItem('/view-applications/drafts', true)"
      >Drafts</a
    >
    <a
      id="app-header-2-a-applications-subbmitted"
      routerLink="/view-applications/submitted"
      (click)="clickedMenuItem('/view-applications/submitted', true)"
      >Submitted/In review</a
    >
    <a
      id="app-header-2-a-applications-returned"
      routerLink="/view-applications/returned"
      (click)="clickedMenuItem('/view-applications/returned', true)"
      >Returned</a
    >
    <a
      id="app-header-2-a-applications-accepted"
      routerLink="/view-applications/accepted"
      (click)="clickedMenuItem('/view-applications/accepted', true)"
      >Accepted</a
    >
    <a
      id="app-header-2-a-applications-accepted"
      routerLink="/view-applications/withdrawn"
      (click)="clickedMenuItem('/view-applications/withdrawn', true)"
      >Withdrawn</a
    >
  </goa-app-header-menu>

  <a
    id="app-header-2-a-allocations-payment"
    href="/allocations-payments"
    [ngClass]="clickedMenu == 'allocations-payments' ? 'hereClicked' : 'hereNotClicked'"
    (click)="clickedMenuItem('allocations-payments', false)"
    >{{ 'allocationsPayments' | res: pageId }}</a
  >

  <goa-app-header-menu
    style="display: none"
    id="app-header-2-a-allocationsPayments"
    class="nav-item-margin-right"
    heading="{{ 'allocationsPayments' | res: pageId }}"
  ></goa-app-header-menu>
  <goa-app-header-menu
    style="display: none"
    heading="{{ 'sfeReports' | res: pageId }}"
    id="app-header-2-a-sfeReports"
    class="nav-item-margin-right"
  ></goa-app-header-menu>
  <a style="display: none" id="app-header-2-a-municipalInfo" href="#" class="nav-item-margin-right">{{
    'municipalInfo' | res: pageId
  }}</a>

  <a
    id="app-header-2-a-resources"
    href="/resources"
    [ngClass]="clickedMenu == 'resources' ? 'hereClicked' : 'hereNotClicked'"
    (click)="clickedMenuItem('resources', false)"
    >{{ 'lgffResources' | res: pageId }}</a
  >
</goa-app-header>

<app-timeout-dialog
  [isOpen]="wantShowLoginTimeoutDlg"
  (afterNoHandler)="noShowIdleTimeoutDialogHandler()"
  (afterYesHandler)="yesShowIdleTimeoutDialogHandler()"
>
</app-timeout-dialog>
