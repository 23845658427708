<form [formGroup]="lgffGoaTableFormGroup">
  <goa-table width="100%" id="lgff-table-{{ tableId }}" (_sort)="handleSort($event)" class="lgff-goa-table">
    <thead>
      <tr>
        <th *ngIf="showCheckboxes" class="col-checkbox">
          <goa-checkbox
            style="height: 28px"
            [name]="'headerAll'"
            checked="{{ headerAllChecked }}"
            (_change)="onHeaderCheckboxChange($event)"
          ></goa-checkbox>
        </th>
        <ng-container *ngFor="let def of colDef; let i = index">
          <th class="{{ def.headerClass }}">
            <ng-container *ngIf="def.sortable">
              <goa-table-sort-header [attr.name]="def.sortName" [attr.direction]="def.sortDir">{{
                def.displayName ?? def.fieldName | res: resPageId
              }}</goa-table-sort-header>
            </ng-container>
            <ng-container *ngIf="!def.sortable">
              {{ def.displayName ?? def.fieldName | res: resPageId }}
            </ng-container>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of tableData">
        <td *ngIf="showCheckboxes" class="col-checkbox">
          <goa-checkbox
            style="height: 28px"
            goaValue
            checked="{{ getFormControl(element['id'] + '').value ? true : false }}"
            formControlName="{{ element['id'] + '' }}"
            value="{{ getFormControl(element['id'] + '').value }}"
            [name]="element['id'] + ''"
            (_change)="onCheckboxChange($event)"
          ></goa-checkbox>
        </td>
        <ng-container *ngFor="let def of colDef">
          <ng-container *ngIf="def.columnTemplate">
            <td class="cell-content {{ def.cellClass }}">
              <ng-container
                *ngTemplateOutlet="
                  def['columnTemplate'];
                  context: {
                    $implicit: element,
                    column: def.fieldName,
                    colDef: def
                  }
                "
              >
              </ng-container>
            </td>
          </ng-container>
          <ng-container *ngIf="!def.columnTemplate">
            <td
              *ngIf="def.onCellClicked"
              class="cell-content clickable-cell {{ def.cellClass }}"
              (click)="def.onCellClicked(element)"
            >
              <abbr style="text-decoration: none; cursor: default" title="{{ element[def.fieldName] }}">{{
                element[def.fieldName] ?? '-'
              }}</abbr>
            </td>
            <td *ngIf="!def.onCellClicked" class="cell-content {{ def.cellClass }}">
              <abbr style="text-decoration: none; cursor: default" title="{{ element[def.fieldName] }}">{{
                element[def.fieldName] ?? '-'
              }}</abbr>
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </tbody>
  </goa-table>
</form>
<div *ngIf="tableData && tableData.length == 0" id="no-result-label">{{ 'noResultMsg' | res }}</div>
<div style="height: 1rem"></div>
<goa-block alignment="center" style="font-size: 18px">
  <goa-block mb="m" alignment="center">
    <span style="width: 135px">{{ 'resultsPerPage' | res: pageId }}</span>
    <goa-dropdown (_change)="handlePerPageChange($event)" value="25" width="8.5ch" relative="true">
      <goa-dropdown-item *ngFor="let size of pageSizeOptions" value="{{ size }}"></goa-dropdown-item>
    </goa-dropdown>
    <span style="width: 150px"
      >{{ tableData && tableData.length == 0 ? 0 : (currentPage - 1) * perPageCount + 1 }} -
      {{ (currentPage - 1) * perPageCount + tableData.length }}
      {{ 'of' | res: pageId }}
      {{ this.totalCount }}</span
    >
  </goa-block>
  <goa-spacer hspacing="fill"></goa-spacer>
  <app-lgff-goa-table-pagination
    [totalItemCount]="totalCount"
    [perPageCount]="perPageCount"
    (pageChanged)="handlePageChange($event)"
  >
  </app-lgff-goa-table-pagination>
</goa-block>
