import { ApplicationStatusType, ProjectDraftDto } from '@app-com/api/models';

export interface ViewApplicationDrafts {
  id: number;
  name: string;
  primaryFunctionalCategoryTitle: string;
  primaryOutcomeTitle: string;
  createdAt: string;
  detailsShown?: boolean;
  projects?: ProjectDraftDto[];

  appNumber?: string; // submit generated, APP-#####
  submittedDate?: string;
  acceptedDate?: string;
  withdrawnAt?: string;
  status?: ApplicationStatusType; //Introduced for Submitted/InReview
}
