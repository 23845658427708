import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AllocationExtListDto, AllocationExtPaymentStatus } from '@app-com/api/models';
import { AllocationExtService } from '@app-com/api/services';
import {
  LgffGoaTableComponent,
  LgffGoADisplayedColumnDef,
} from '@app-com/components/lgff-goa-table/lgff-goa-table.component';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-allocations-and-payments',
  templateUrl: './allocations-and-payments.component.html',
  styleUrl: './allocations-and-payments.component.scss',
})
export class AllocationsAndPaymentsComponent implements OnInit {
  @ViewChild('allocPaymentCurrencyColTemplate', { static: true })
  allocPaymentCurrencyColTemplate: TemplateRef<AllocationExtListDto> | null = null;

  @ViewChild('allocPaymentStatusColTemplate', { static: true })
  allocPaymentStatusColTemplate: TemplateRef<AllocationExtListDto> | null = null;

  @ViewChild('allocPaymentCriteriaMetColTemplate', { static: true })
  allocPaymentCriteriaMetColTemplate: TemplateRef<AllocationExtListDto> | null = null;

  @ViewChild(LgffGoaTableComponent) lgffGoaTable: LgffGoaTableComponent;

  pageId = 'ALLOCATIONS_PAYMENTS';
  searchFormCtrl: FormControl;
  searchTerm = '';
  totalAllocAmount = 0;
  totalPaidAmount = 0;
  colDef: LgffGoADisplayedColumnDef[] = [];
  tableData: Array<AllocationExtListVM> = [];
  filteredData: Array<AllocationExtListVM> = [];
  displayedData: Array<AllocationExtListVM> = [];
  currentPage = 1;
  perPageCount = 25;
  displayPaginator = true;
  pageSizeOptions = [10, 25, 50, 100];
  unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store,
    private apiService: AllocationExtService,
  ) {}

  ngOnInit(): void {
    this.searchFormCtrl = new FormControl('');
    this.searchFormCtrl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((newValue: string) => {
      if (!newValue) {
        this.searchTerm = newValue;
        this.applySearchFilter();
      }
    });
    this.loadData();
    this.initColDef();
  }

  initColDef(): void {
    this.colDef = [
      { fieldName: 'programYear', sortable: true, sortName: 'programYear', sortDir: 'desc' },
      {
        fieldName: 'totalAllocation',
        sortable: true,
        sortName: 'totalAllocation',
        headerClass: 'goa-table-number-header',
        cellClass: 'goa-table-number-column',
        columnTemplate: this.allocPaymentCurrencyColTemplate,
      },
      {
        fieldName: 'paid',
        sortable: true,
        sortName: 'paid',
        headerClass: 'goa-table-number-header',
        cellClass: 'goa-table-number-column',
        columnTemplate: this.allocPaymentCurrencyColTemplate,
      },
      {
        fieldName: 'paymentStatusLabel',
        sortable: true,
        sortName: 'paymentStatusLabel',
        columnTemplate: this.allocPaymentStatusColTemplate,
        misc: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getBadgeType: (status: AllocationExtPaymentStatus) => {
            if (status == AllocationExtPaymentStatus.PaidReconciled) {
              return 'success';
            }
            return 'information';
          },
        },
      },
      {
        fieldName: 'paidAtString',
        sortable: true,
        sortName: 'paidAt',
      },
      {
        fieldName: 'isPaymentCriteriaMet',
        sortable: true,
        sortName: 'isPaymentCriteriaMetLabel',
        columnTemplate: this.allocPaymentCriteriaMetColTemplate,
      },
    ];
  }

  loadData() {
    this.displayPaginator = false;
    const currentOrganizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    this.apiService.getAllocationSummary({ organizationId: currentOrganizationId }).subscribe({
      next: (result: Array<AllocationExtListDto>) => {
        this.tableData = [];
        result.forEach((alloc: AllocationExtListDto) => {
          this.tableData.push(new AllocationExtListVM(alloc));
        });
        this.totalAllocAmount = 0;
        this.totalPaidAmount = 0;
        result.forEach((obj) => {
          this.totalAllocAmount = Number(this.totalAllocAmount) + Number(obj.totalAllocation);
          this.totalPaidAmount = Number(this.totalPaidAmount) + Number(obj.paid);
        });

        this.totalAllocAmount = Number(this.totalAllocAmount);
        this.totalPaidAmount = Number(this.totalPaidAmount);
        this.displayPaginator = true;
        this.applySearchFilter();
      },
      error: (error: Error) => {
        console.log(
          'file: allocations-and-payments.component.ts:80 ~ this.apiService.getAllocationSummary ~ error:',
          error,
        );
        this.displayPaginator = true;
      },
    });
  }

  applySearchFilter() {
    this.searchTerm = this.searchFormCtrl.value;
    this.filteredData = this.tableData.filter((item) =>
      Object.values(item).some((field) => {
        try {
          return field.toString().toLowerCase().includes(this.searchTerm);
        } catch (e) {
          //some fields may not have toString method, so it's expected to have some errors here. Nothing need to be logged.
        }
        return false;
      }),
    );
    this.currentPage = 1;
    this.lgffGoaTable.informPagination(new CustomEvent('onPageChange', { detail: { page: this.currentPage } }));
    this.calculateDisplayedData(this.currentPage);
  }

  handleSort(event: Event) {
    const { sortBy, sortDir } = (event as CustomEvent).detail;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.filteredData.sort((a: any, b: any) => {
      if (sortBy == 'totalAllocation' || sortBy == 'paid') {
        return Number(a[sortBy]) > Number(b[sortBy]) ? 1 * sortDir : -1 * sortDir;
      }
      return a[sortBy] > b[sortBy] ? 1 * sortDir : -1 * sortDir;
    });
    this.calculateDisplayedData(this.currentPage);
  }

  handlePageChange(event: Event) {
    const e = event as CustomEvent;
    this.currentPage = e.detail.page ?? 1;
    this.calculateDisplayedData(this.currentPage);
  }

  handlePerPageChange(event: Event) {
    const e = event as CustomEvent;
    this.perPageCount = Number(e.detail.value) ?? 25;
    this.currentPage = 1;
    this.calculateDisplayedData(this.currentPage);
  }

  calculateDisplayedData(currentPage: number) {
    const offset = (currentPage - 1) * this.perPageCount;
    this.displayedData = this.filteredData.slice(offset, offset + this.perPageCount);
  }
}

export class AllocationExtListVM implements AllocationExtListDto {
  id: number;
  isPaymentCriteriaMet: boolean;
  isPaymentCriteriaMetLabel: string;
  paid: number;
  paidAt: string;
  paidAtString: string;
  paymentStatus: AllocationExtPaymentStatus;
  paymentStatusLabel: string;
  programYear: string;
  totalAllocation: number;

  constructor(data: AllocationExtListDto) {
    Object.assign(this, data);
    this.paidAtString = data.paidAt ? CommUtilsService.getDateStrMonDdYear(data.paidAt) : '-';
    this.isPaymentCriteriaMetLabel = this.isPaymentCriteriaMet ? 'yes' : 'no';
  }
}
