import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription, filter } from 'rxjs';
import { ClearApplicationDraftContext } from '@app-pot/store/actions/current-context.action';
import { SubmittedApplicationsComponent } from './submitted-applications/submitted-applications.component';
import { DraftApplicationsComponent } from './draft-applications/draft-applications.component';
import { FormControl } from '@angular/forms';
import { ViewApplicationTab } from './view-application-tab.enum';
import { ApplicationExtService } from '@app-com/api/services';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { ApplicationStatusType } from '@app-com/api/models';
import { ReturnedApplicationsComponent } from './returned-applications/returned-applications.component';
import { AcceptedApplicationsComponent } from './accepted-applications/accepted-applications.component';
import { WithdrawnApplicationsComponent } from './withdrawn-applications/withdrawn-applications.component';
import { ActiveTabService } from './active-tab.service';

@Component({
  selector: 'app-view-applications',
  templateUrl: './view-applications.component.html',
  styleUrls: ['./view-applications.component.scss'],
})
export class ViewApplicationsComponent implements OnInit, AfterViewInit {
  pageId = 'VIEW_APPLICATIONS';
  applicationName: string;
  successMessage: string;
  sub = new Subscription();
  searchStr: string = '';
  searchFormControl: FormControl;

  activeTab?: string = 'drafts';
  @ViewChild('submittedApplications') submittedApplications: SubmittedApplicationsComponent;
  @ViewChild('acceptedApplications') acceptedApplications: AcceptedApplicationsComponent;
  @ViewChild('draftApplications') draftApplications: DraftApplicationsComponent;
  @ViewChild('returnedApplications') returnedApplications: ReturnedApplicationsComponent;
  @ViewChild('withdrawnApplications') withdrawnApplications: WithdrawnApplicationsComponent;

  returnedApplicationsCount: string;
  draftApplicationsCount: string;
  submittedApplicationsCount: string;
  acceptedApplicationsCount: string;
  withdrawnApplicationsCount: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private applicationService: ApplicationExtService,
    private activeTabService: ActiveTabService,
    private cd: ChangeDetectorRef,
  ) {}
  ngAfterViewInit(): void {}

  applySearchFilter() {
    this.activeTabService.setActiveTabSearch(this.searchFormControl.value.toLocaleLowerCase());
  }

  clearSearchFilters(event: Event) {
    const searchInput = ((event.target as HTMLInputElement).value ?? '').trim().toLocaleLowerCase();

    if (searchInput.length === 0) {
      this.activeTabService.setActiveTabSearch('');
    }
  }

  ngOnInit(): void {
    this.sub.add(
      this.activeTabService
        .getActiveTab()
        .pipe(filter((tab) => tab !== undefined))
        .subscribe((tab) => {
          this.activeTab = tab;
          this.cd.detectChanges();
        }),
    );

    const body = Array.from(document.getElementsByClassName('mat-typography') as HTMLCollectionOf<HTMLElement>);
    body.forEach((b) => {
      b.style.overflow = 'auto';
    });

    this.searchFormControl = new FormControl('');
    this._setListCounts();
  }

  private _setListCounts() {
    const organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    this.sub.add(
      this.applicationService
        .countAllDrafts({ organizationId })
        .subscribe((_) => (this.draftApplicationsCount = _.toString())),
    );

    this.sub.add(
      this.applicationService
        .countByOrganization({
          organizationId,
          body: { statuses: [ApplicationStatusType.Submitted, ApplicationStatusType.InReview] },
        })
        .subscribe((_) => (this.submittedApplicationsCount = _.toString())),
    );

    this.sub.add(
      this.applicationService
        .countByOrganization({
          organizationId,
          body: { statuses: [ApplicationStatusType.Accepted] },
        })
        .subscribe((_) => (this.acceptedApplicationsCount = _.toString())),
    );

    this.sub.add(
      this.applicationService
        .countByOrganization({
          organizationId,
          body: { statuses: [ApplicationStatusType.Returned] },
        })
        .subscribe((_) => (this.returnedApplicationsCount = _.toString())),
    );

    this.sub.add(
      this.applicationService
        .countByOrganization({
          organizationId,
          body: { statuses: [ApplicationStatusType.Withdrawn] },
        })
        .subscribe((_) => (this.withdrawnApplicationsCount = _.toString())),
    );
  }

  private _getTabIndex(viewApplicationTab: ViewApplicationTab) {
    return viewApplicationTab == ViewApplicationTab.Drafts
      ? 1
      : viewApplicationTab == ViewApplicationTab.Submitted
        ? 2
        : viewApplicationTab == ViewApplicationTab.Returned
          ? 3
          : viewApplicationTab == ViewApplicationTab.Accepted
            ? 4
            : viewApplicationTab == ViewApplicationTab.Withdrawn
              ? 5
              : undefined;
  }

  public tabChanged(tab: string) {
    this.router.navigate([tab], { relativeTo: this.activatedRoute });
  }

  private adjustLinks() {
    const goa_tabs = document.querySelectorAll('goa-button[role="tab"]');
    goa_tabs.forEach((goa_tab) => {
      console.log('Goa tab', goa_tab);
      goa_tab.shadowRoot?.querySelector('button.tertiary.normal.normal')?.addEventListener('mouseenter', function (e) {
        //(e.currentTarget as unknown as HTMLButtonElement).style!.backgroundColor = 'none !important';
        (e.currentTarget as unknown as HTMLButtonElement).style!.backgroundColor = 'initial';
        (e.currentTarget as unknown as HTMLButtonElement).style!.border = 'none !important';
        (e.currentTarget as unknown as HTMLButtonElement).style!.borderColor = 'transparent';
      });
      goa_tab.shadowRoot?.querySelector('button.tertiary.normal.normal')?.addEventListener('mouseleave', function (e) {
        (e.currentTarget as unknown as HTMLButtonElement).style!.backgroundColor = 'initial';
        (e.currentTarget as unknown as HTMLButtonElement).style!.border = 'none !important';
        (e.currentTarget as unknown as HTMLButtonElement).style!.borderColor = 'transparent';
      });
      goa_tab.shadowRoot?.querySelector('button.tertiary.normal.normal')?.addEventListener('click', function (e) {
        (e.currentTarget as unknown as HTMLButtonElement).style!.backgroundColor = 'initial';
        (e.currentTarget as unknown as HTMLButtonElement).style!.border = 'none !important';
        (e.currentTarget as unknown as HTMLButtonElement).style!.borderColor = 'transparent';
      });
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  newApplicationBtnClick() {
    this.store.dispatch(new ClearApplicationDraftContext());
    this.router.navigate(['grant-application'], { state: { applicationDraftId: 0 } });
  }
}
