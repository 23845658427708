import { FormSequence } from '@app-pot/features/grant-application/models/enums';

export class SetCurrentOrganizationId {
  static readonly type = '[CurrentContext] SetCurrentOrganizationId';
  constructor(public payload: number) {}
}

export class SetCurrentApplicationDraftId {
  static readonly type = '[CurrentContext] SetCurrentApplicationDraftId';
  constructor(public payload?: number) {}
}

export class SetCurrentApplicationDraftStep {
  static readonly type = '[CurrentContext] SetCurrentApplicationDraftStep';
  constructor(public payload?: FormSequence) {}
}

export class ClearContextOnLogout {
  static readonly type = '[CurrentContext] ClearContextOnLogout';
  constructor() {}
}

export class ClearApplicationDraftContext {
  static readonly type = '[CurrentContext] ClearApplicationDraftContext';
  constructor() {}
}
