import { Injectable } from '@angular/core';
import {
  ApplicationDraftDto,
  ApplicationExtDto,
  ApplicationReturnedDto,
  ApplicationStatusType,
} from '@app-com/api/models';
import { ApplicationExtService } from '@app-com/api/services';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  FetchAcceptedApplications,
  FetchApplicationDrafts,
  FetchReturnedApplications,
  FetchSubmittedApplication,
  FetchWithdrawnApplications,
  RemoveApplicationFromStateByDraftId,
  RemoveReturnedApplicationFromStateByDraftId,
} from '../actions/applications.action';
import { tap } from 'rxjs';
import { CurrentContextState } from './current-context.state';

export class ApplicationsStateModel {
  applicationDrafts: ApplicationDraftDto[];
  applicationsSubmitted: ApplicationExtDto[];
  applicationsAccepted: ApplicationExtDto[];
  applicationsWithdrawn: ApplicationExtDto[];
  applicationsReturned: ApplicationReturnedDto[];
}

@State<ApplicationsStateModel>({
  name: 'applications',
  defaults: {
    applicationDrafts: [],
    applicationsSubmitted: [],
    applicationsAccepted: [],
    applicationsReturned: [],
    applicationsWithdrawn: [],
  },
})
@Injectable()
export class ApplicationsState {
  constructor(
    private applicationService: ApplicationExtService,
    private store: Store,
  ) {
    this.store.select(CurrentContextState.getCurrentOrganizationId).subscribe((currentOrganizationId) => {
      this.currentOrganizationId = currentOrganizationId;
    });
  }
  currentOrganizationId: number;

  @Selector()
  static fetchApplications(state: ApplicationsStateModel) {
    return state.applicationDrafts;
  }
  @Selector()
  static fetchSubmittedApplications(state: ApplicationsStateModel) {
    return state.applicationsSubmitted;
  }

  @Selector()
  static fetchAcceptedApplications(state: ApplicationsStateModel) {
    return state.applicationsAccepted;
  }

  @Selector()
  static fetchWithdawnApplications(state: ApplicationsStateModel) {
    return state.applicationsWithdrawn;
  }

  @Selector()
  static fetchReturnedApplications(state: ApplicationsStateModel) {
    return state.applicationsReturned;
  }

  @Action(FetchSubmittedApplication)
  fetchSubmittedApplication({ getState, setState }: StateContext<ApplicationsStateModel>) {
    return this.applicationService
      .findAllByOrganization({
        organizationId: this.currentOrganizationId,
        body: { statuses: [ApplicationStatusType.Submitted, ApplicationStatusType.InReview] },
      })
      .pipe(
        tap((applications: ApplicationExtDto[]) => {
          setState(
            patch({
              applicationsSubmitted: applications,
            }),
          );
          console.log('current store state', getState());
        }),
      );
  }

  @Action(FetchAcceptedApplications)
  fetchAcceptedApplications({ getState, setState }: StateContext<ApplicationsStateModel>) {
    return this.applicationService
      .findAllByOrganization({
        organizationId: this.currentOrganizationId,
        body: { statuses: [ApplicationStatusType.Accepted] },
      })
      .pipe(
        tap((applications: ApplicationExtDto[]) => {
          setState(
            patch({
              applicationsAccepted: applications,
            }),
          );
          console.log('current store state', getState());
        }),
      );
  }

  @Action(FetchWithdrawnApplications)
  fetchWithdrawnApplications({ getState, setState }: StateContext<ApplicationsStateModel>) {
    return this.applicationService
      .findAllByOrganization({
        organizationId: this.currentOrganizationId,
        body: { statuses: [ApplicationStatusType.Withdrawn] },
      })
      .pipe(
        tap((applications: ApplicationExtDto[]) => {
          setState(
            patch({
              applicationsWithdrawn: applications,
            }),
          );
          console.log('current store state', getState());
        }),
      );
  }

  @Action(FetchApplicationDrafts)
  fetchApplicationDrafts({ getState, setState }: StateContext<ApplicationsStateModel>) {
    return this.applicationService.findAllDrafts({ organizationId: this.currentOrganizationId }).pipe(
      tap((applications: ApplicationDraftDto[]) => {
        setState(
          patch({
            applicationDrafts: applications, // need default sorting
          }),
        );
        console.log('current store state', getState());
      }),
    );
  }

  @Action(FetchReturnedApplications)
  fetchReturnedApplications({ getState, setState }: StateContext<ApplicationsStateModel>) {
    return this.applicationService.findAllReturned({ organizationId: this.currentOrganizationId }).pipe(
      tap((applications: ApplicationReturnedDto[]) => {
        setState(
          patch({
            applicationsReturned: applications,
          }),
        );
        console.log('current store state', getState());
      }),
    );
  }

  @Action(RemoveApplicationFromStateByDraftId)
  removeApplicationFromStateByDraftId(
    { getState, setState }: StateContext<ApplicationsStateModel>,
    { applicationDraftId }: RemoveApplicationFromStateByDraftId,
  ) {
    const curState = getState();
    if (curState && curState.applicationDrafts && curState.applicationDrafts.length > 0) {
      console.log('State before remove: ' + applicationDraftId);
      console.log(curState.applicationDrafts);

      const filteredApps = curState.applicationDrafts.filter((draft) => draft.id != applicationDraftId);
      console.log('State after remove:');
      console.log(filteredApps);

      setState(
        patch({
          applicationDrafts: filteredApps,
          applicationsSubmitted: curState.applicationsSubmitted,
        }),
      );
    }
  }

  @Action(RemoveReturnedApplicationFromStateByDraftId)
  removeReturnedApplicationFromStateByDraftId(
    { getState, setState }: StateContext<ApplicationsStateModel>,
    { applicationDraftId }: RemoveReturnedApplicationFromStateByDraftId,
  ) {
    const curState = getState();
    if (curState && curState.applicationsReturned && curState.applicationsReturned.length > 0) {
      const filteredApps = curState.applicationsReturned.filter((draft) => draft.draft_id != applicationDraftId);
      setState(
        patch({
          applicationsReturned: filteredApps,
        }),
      );
    }
  }
}
