import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActiveTabService {
  private activeTabSubject = new BehaviorSubject<string | undefined>(undefined);
  private activeTab$: Observable<string | undefined> = this.activeTabSubject.asObservable();

  private activeTabSearchSubject = new BehaviorSubject<string | undefined>(undefined);
  private activeTabSearch$: Observable<string | undefined> = this.activeTabSearchSubject.asObservable();

  constructor() {
    console.log('Active tab service instantiated');
  }

  public getActiveTab(): Observable<string | undefined> {
    return this.activeTab$;
  }

  public getActiveTabSearch(): Observable<string | undefined> {
    return this.activeTabSearch$;
  }

  public setActiveTab(tab: string | undefined) {
    if (tab) {
      this.activeTabSubject.next(tab);
    }
  }
  public setActiveTabSearch(search: string | undefined) {
    if (search !== undefined) {
      this.activeTabSearchSubject.next(search);
    }
  }
}
