/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ApplicationDraftDto } from '../models/application-draft-dto';
import { ApplicationExtDto } from '../models/application-ext-dto';
import { ApplicationFilter } from '../models/application-filter';
import { ApplicationReturnedDto } from '../models/application-returned-dto';
import { CreateApplicationDraftDto } from '../models/create-application-draft-dto';
import { PdfGenerateResponseDto } from '../models/pdf-generate-response-dto';
import { UpdateApplicationDraftDto } from '../models/update-application-draft-dto';

@Injectable({ providedIn: 'root' })
export class ApplicationExtService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createDraft()` */
  static readonly CreateDraftPath = '/api/applications-ext/draft/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDraft()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDraft$Response(
    params: {
      organizationId: number;
      body: CreateApplicationDraftDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDraftDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.CreateDraftPath, 'post');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDraftDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDraft$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDraft(
    params: {
      organizationId: number;
      body: CreateApplicationDraftDto
    },
    context?: HttpContext
  ): Observable<ApplicationDraftDto> {
    return this.createDraft$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDraftDto>): ApplicationDraftDto => r.body)
    );
  }

  /** Path part for operation `countAllDrafts()` */
  static readonly CountAllDraftsPath = '/api/applications-ext/drafts/count/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countAllDrafts()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAllDrafts$Response(
    params: {
      organizationId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.CountAllDraftsPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countAllDrafts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAllDrafts(
    params: {
      organizationId: number;
    },
    context?: HttpContext
  ): Observable<number> {
    return this.countAllDrafts$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAllDrafts()` */
  static readonly FindAllDraftsPath = '/api/applications-ext/drafts/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllDrafts()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllDrafts$Response(
    params: {
      organizationId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationDraftDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.FindAllDraftsPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationDraftDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllDrafts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllDrafts(
    params: {
      organizationId: number;
    },
    context?: HttpContext
  ): Observable<Array<ApplicationDraftDto>> {
    return this.findAllDrafts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationDraftDto>>): Array<ApplicationDraftDto> => r.body)
    );
  }

  /** Path part for operation `findOneDraft()` */
  static readonly FindOneDraftPath = '/api/applications-ext/draft/{organizationId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOneDraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOneDraft$Response(
    params: {
      organizationId: number;
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDraftDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.FindOneDraftPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDraftDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOneDraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOneDraft(
    params: {
      organizationId: number;
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationDraftDto> {
    return this.findOneDraft$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDraftDto>): ApplicationDraftDto => r.body)
    );
  }

  /** Path part for operation `removeDraft()` */
  static readonly RemoveDraftPath = '/api/applications-ext/draft/{organizationId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeDraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeDraft$Response(
    params: {
      organizationId: number;
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDraftDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.RemoveDraftPath, 'delete');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDraftDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeDraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeDraft(
    params: {
      organizationId: number;
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationDraftDto> {
    return this.removeDraft$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDraftDto>): ApplicationDraftDto => r.body)
    );
  }

  /** Path part for operation `updateDraft()` */
  static readonly UpdateDraftPath = '/api/applications-ext/draft/{organizationId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDraft()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDraft$Response(
    params: {
      organizationId: number;
      id: number;
      body: UpdateApplicationDraftDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDraftDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.UpdateDraftPath, 'patch');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDraftDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDraft$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDraft(
    params: {
      organizationId: number;
      id: number;
      body: UpdateApplicationDraftDto
    },
    context?: HttpContext
  ): Observable<ApplicationDraftDto> {
    return this.updateDraft$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDraftDto>): ApplicationDraftDto => r.body)
    );
  }

  /** Path part for operation `findAllReturned()` */
  static readonly FindAllReturnedPath = '/api/applications-ext/returned/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllReturned()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllReturned$Response(
    params: {
      organizationId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationReturnedDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.FindAllReturnedPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationReturnedDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllReturned$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllReturned(
    params: {
      organizationId: number;
    },
    context?: HttpContext
  ): Observable<Array<ApplicationReturnedDto>> {
    return this.findAllReturned$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationReturnedDto>>): Array<ApplicationReturnedDto> => r.body)
    );
  }

  /** Path part for operation `submitApplication()` */
  static readonly SubmitApplicationPath = '/api/applications-ext/submit/{organizationId}/{draftId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  submitApplication$Response(
    params: {
      organizationId: number;
      draftId: number;
      certifiedByRole: 'CAO' | 'CAORepresentative';
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationExtDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.SubmitApplicationPath, 'post');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('draftId', params.draftId, {});
      rb.query('certifiedByRole', params.certifiedByRole, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationExtDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  submitApplication(
    params: {
      organizationId: number;
      draftId: number;
      certifiedByRole: 'CAO' | 'CAORepresentative';
    },
    context?: HttpContext
  ): Observable<ApplicationExtDto> {
    return this.submitApplication$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationExtDto>): ApplicationExtDto => r.body)
    );
  }

  /** Path part for operation `countByOrganization()` */
  static readonly CountByOrganizationPath = '/api/applications-ext/count/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countByOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  countByOrganization$Response(
    params: {
      organizationId: number;
      body: ApplicationFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.CountByOrganizationPath, 'post');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countByOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  countByOrganization(
    params: {
      organizationId: number;
      body: ApplicationFilter
    },
    context?: HttpContext
  ): Observable<number> {
    return this.countByOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAllByOrganization()` */
  static readonly FindAllByOrganizationPath = '/api/applications-ext/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllByOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAllByOrganization$Response(
    params: {
      organizationId: number;
      take?: number;
      skip?: number;
      body: ApplicationFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationExtDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.FindAllByOrganizationPath, 'post');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationExtDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllByOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAllByOrganization(
    params: {
      organizationId: number;
      take?: number;
      skip?: number;
      body: ApplicationFilter
    },
    context?: HttpContext
  ): Observable<Array<ApplicationExtDto>> {
    return this.findAllByOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationExtDto>>): Array<ApplicationExtDto> => r.body)
    );
  }

  /** Path part for operation `findOneByOrganization()` */
  static readonly FindOneByOrganizationPath = '/api/applications-ext/{organizationId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOneByOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOneByOrganization$Response(
    params: {
      organizationId: number;
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationExtDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.FindOneByOrganizationPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationExtDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOneByOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOneByOrganization(
    params: {
      organizationId: number;
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationExtDto> {
    return this.findOneByOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationExtDto>): ApplicationExtDto => r.body)
    );
  }

  /** Path part for operation `pdfApplicationList()` */
  static readonly PdfApplicationListPath = '/api/applications-ext/pdf/application-list/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pdfApplicationList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pdfApplicationList$Response(
    params: {
      organizationId: number;
      body: ApplicationFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PdfGenerateResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.PdfApplicationListPath, 'post');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdfGenerateResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pdfApplicationList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pdfApplicationList(
    params: {
      organizationId: number;
      body: ApplicationFilter
    },
    context?: HttpContext
  ): Observable<PdfGenerateResponseDto> {
    return this.pdfApplicationList$Response(params, context).pipe(
      map((r: StrictHttpResponse<PdfGenerateResponseDto>): PdfGenerateResponseDto => r.body)
    );
  }

  /** Path part for operation `pdfApplicationDraftList()` */
  static readonly PdfApplicationDraftListPath = '/api/applications-ext/pdf/application-draft-list/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pdfApplicationDraftList()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfApplicationDraftList$Response(
    params: {
      organizationId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PdfGenerateResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.PdfApplicationDraftListPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdfGenerateResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pdfApplicationDraftList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfApplicationDraftList(
    params: {
      organizationId: number;
    },
    context?: HttpContext
  ): Observable<PdfGenerateResponseDto> {
    return this.pdfApplicationDraftList$Response(params, context).pipe(
      map((r: StrictHttpResponse<PdfGenerateResponseDto>): PdfGenerateResponseDto => r.body)
    );
  }

  /** Path part for operation `pdfApplicationDraftDetails()` */
  static readonly PdfApplicationDraftDetailsPath = '/api/applications-ext/pdf/application-draft-details/{organizationId}/{draftId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pdfApplicationDraftDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfApplicationDraftDetails$Response(
    params: {
      organizationId: number;
      draftId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PdfGenerateResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.PdfApplicationDraftDetailsPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('draftId', params.draftId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdfGenerateResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pdfApplicationDraftDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfApplicationDraftDetails(
    params: {
      organizationId: number;
      draftId: number;
    },
    context?: HttpContext
  ): Observable<PdfGenerateResponseDto> {
    return this.pdfApplicationDraftDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<PdfGenerateResponseDto>): PdfGenerateResponseDto => r.body)
    );
  }

  /** Path part for operation `pdfReturnedApplicationDetails()` */
  static readonly PdfReturnedApplicationDetailsPath = '/api/applications-ext/pdf/application-returned-details/{organizationId}/{draftId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pdfReturnedApplicationDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfReturnedApplicationDetails$Response(
    params: {
      organizationId: number;
      draftId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PdfGenerateResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.PdfReturnedApplicationDetailsPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('draftId', params.draftId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdfGenerateResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pdfReturnedApplicationDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfReturnedApplicationDetails(
    params: {
      organizationId: number;
      draftId: number;
    },
    context?: HttpContext
  ): Observable<PdfGenerateResponseDto> {
    return this.pdfReturnedApplicationDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<PdfGenerateResponseDto>): PdfGenerateResponseDto => r.body)
    );
  }

  /** Path part for operation `pdfApplicationDetails_1()` */
  static readonly PdfApplicationDetails_1Path = '/api/applications-ext/pdf/application-details/{organizationId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pdfApplicationDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfApplicationDetails$Response(
    params: {
      organizationId: number;
      id: number;
      version: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PdfGenerateResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationExtService.PdfApplicationDetails_1Path, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
      rb.query('version', params.version, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdfGenerateResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pdfApplicationDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfApplicationDetails(
    params: {
      organizationId: number;
      id: number;
      version: string;
    },
    context?: HttpContext
  ): Observable<PdfGenerateResponseDto> {
    return this.pdfApplicationDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<PdfGenerateResponseDto>): PdfGenerateResponseDto => r.body)
    );
  }

}
