import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApplicationDraftRecordDto } from '@app-com/api/models';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';
import { ApplicationGrantState } from '@app-pot/store/state/application-draft.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, debounceTime, filter, takeWhile, skipUntil, timer } from 'rxjs';

import { PageIds, UiFieldCtrDef, ValidationPatterns } from '@app-com/constants/patterns';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { BaseStepperComponent } from '../base-stepper/base-stepper.component';

import { LoadPrimaryOutcomes } from '@app-pot/store/actions/lookup-value.action';
import { LookupValue, LookupValueState } from '@app-pot/store/state/lookup-value.state';
import { AutosaveGeneralInfo } from '@app-pot/store/actions/application-draft.action';

@Component({
  selector: 'app-grant-application-general-info',
  templateUrl: './grant-application-general-info.component.html',
  styleUrls: ['./grant-application-general-info.component.scss'],
})
export class GrantApplicationGeneralInfoComponent
  extends BaseStepperComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public PageIds = PageIds;
  public ValidationPatterns = ValidationPatterns;
  pageId = 'GENERAL_INFORMATION';

  @Select(ApplicationGrantState.fetchApplicationDto) fetchApplication$: Observable<ApplicationDraftRecordDto>;
  @Select(ApplicationGrantState.existingApplicationDraft) existingApplicationDraft$: Observable<boolean>;
  @Select(LookupValueState.getPrimaryOutcomes) primaryOutcomes$: Observable<LookupValue[]>;

  generalInfoForm: FormGroup;

  CurrentPageDef = PageIds.app.general;
  UiDefFirstName: UiFieldCtrDef;
  UiDefLastName: UiFieldCtrDef;
  UiDefPhone: UiFieldCtrDef;
  UiDefEmail: UiFieldCtrDef;
  UiDefApplicationName: UiFieldCtrDef;
  UiDefOutcomes: UiFieldCtrDef;
  UiDefDescription: UiFieldCtrDef;
  pageUiDefs: UiFieldCtrDef[] = [];

  existingDraft: boolean;
  sub = new Subscription();
  isAlive = true;
  isDropdownStyleAdjusted = false;
  isAutosaveNeeded = false;
  timeoutIds: ReturnType<typeof setTimeout>[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
    private store: Store,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadPrimaryOutcomes());
    this.generalInfoForm = this.formBuilder.group({});
    this.createFormControls();
    this.pullInitFormData();

    this.pageUiDefs.forEach((uiDef) => {
      uiDef.formCtr.valueChanges
        .pipe(takeWhile(() => this.isAlive))
        .pipe(debounceTime(300))
        .subscribe(() => {
          if (uiDef.focusedInNonBlankOrErrorField || uiDef.focusedOutFieldByTrueBlurEvent) {
            CommUtilsService.getUiFieldErrorList(uiDef, uiDef.focusedOutFieldByTrueBlurEvent ?? false, 'valueChanges');
          }
        });
    });

    this._registerChangeObserverForAutosaveFlag();
  }

  //This is to prevent autosave from being triggered when a user simply tabs through input fields.
  private _registerChangeObserverForAutosaveFlag() {
    this.generalInfoForm.valueChanges
      .pipe(
        takeWhile(() => this.isAlive),
        skipUntil(timer(500)), //When the form is open in edit mode and values are being populated, the change event may trigger multiple times. This is to skip those initial changes.
      )
      .subscribe(() => (this.isAutosaveNeeded = true));
  }

  autosave() {
    if (!this.isAutosaveNeeded) return;
    this.isAutosaveNeeded = false;
    const generalInfo = this.GetGeneralInfo();
    const applicationDraftId = this.store.selectSnapshot(ApplicationGrantState.getApplicationDraftId);
    if (
      !applicationDraftId &&
      !generalInfo.contactFirstName?.trim() &&
      !generalInfo.contactLastName?.trim() &&
      !generalInfo.contactPhoneNumber?.trim() &&
      !generalInfo.contactEmailAddress?.trim() &&
      !generalInfo.name?.trim() &&
      !generalInfo.primaryOutcomeId &&
      !generalInfo.description?.trim()
    ) {
      return;
    }
    if (!generalInfo.name?.trim()) generalInfo.name = 'No Name drafted on ' + this._getCurrentDateTime(); // TODO: This name should be generated from the backend instead of the frontend
    this.store.dispatch(new AutosaveGeneralInfo(generalInfo));
  }

  public _getCurrentDateTime(): string {
    const currentDate = new Date();
    const formattedDateTime = currentDate.toLocaleString();
    return formattedDateTime;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.isDropdownStyleAdjusted) {
        this._adjustGoaDropdownStyle(this.UiDefOutcomes.id + '-wrap');
        this.isDropdownStyleAdjusted = true;
      }
      this.updateCSSforDescription();
      this.adjustShadowDomCss();
    }, 500);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  // create form controls for both blank-application and editing-application, init value are ''
  createFormControls() {
    this.generalInfoForm = this.formBuilder.group({});

    this.UiDefFirstName = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.firstName);
    this.generalInfoForm.addControl(this.UiDefFirstName.nameCtr, this.UiDefFirstName.formCtr);

    this.UiDefLastName = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.lastName);
    this.generalInfoForm.addControl(this.UiDefLastName.nameCtr, this.UiDefLastName.formCtr);

    this.UiDefPhone = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.phone);
    this.generalInfoForm.addControl(this.UiDefPhone.nameCtr, this.UiDefPhone.formCtr);

    this.UiDefEmail = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.email);
    this.generalInfoForm.addControl(this.UiDefEmail.nameCtr, this.UiDefEmail.formCtr);

    this.UiDefApplicationName = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.applicationName);
    this.generalInfoForm.addControl(this.UiDefApplicationName.nameCtr, this.UiDefApplicationName.formCtr);

    this.UiDefOutcomes = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.primaryOutcome);
    this.generalInfoForm.addControl(this.UiDefOutcomes.nameCtr, this.UiDefOutcomes.formCtr);

    this.UiDefDescription = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.description);
    this.generalInfoForm.addControl(this.UiDefDescription.nameCtr, this.UiDefDescription.formCtr);

    this.pageUiDefs = [
      this.UiDefFirstName,
      this.UiDefLastName,
      this.UiDefPhone,
      this.UiDefEmail,
      this.UiDefApplicationName,
      this.UiDefOutcomes,
      this.UiDefDescription,
    ];
  }

  pullInitFormData() {
    this.sub.add(
      this.existingApplicationDraft$.subscribe((existingDraft) => {
        this.existingDraft = existingDraft;
      }),
    );
    this.sub.add(
      this.fetchApplication$
        .pipe(filter((fetchedApplication) => !!fetchedApplication))
        .subscribe((initialGeneralInfoForm) => {
          if (initialGeneralInfoForm && this.existingDraft && this.isAlive) {
            // may have timing issue, if existingDraft arrive later
            console.log('General info page form initialized', initialGeneralInfoForm);
            this.generalInfoForm.setValue({
              [this.UiDefFirstName.nameCtr]: initialGeneralInfoForm.contactFirstName ?? '',
              [this.UiDefLastName.nameCtr]: initialGeneralInfoForm.contactLastName ?? '',
              [this.UiDefPhone.nameCtr]: initialGeneralInfoForm.contactPhoneNumber ?? '',
              [this.UiDefEmail.nameCtr]: initialGeneralInfoForm.contactEmailAddress ?? '',
              [this.UiDefApplicationName.nameCtr]: initialGeneralInfoForm.name ?? '',
              [this.UiDefOutcomes.nameCtr]: initialGeneralInfoForm.primaryOutcomeId ?? '',
              [this.UiDefDescription.nameCtr]: initialGeneralInfoForm.description ?? '',
            });
          }
        }),
    );
  }

  updateCSSforDescription() {
    const txtareadescription = Array.from(document.getElementsByClassName('txtdescription'));
    txtareadescription.forEach((stepElm) => {
      stepElm.shadowRoot?.childNodes.forEach((childNode) => {
        if (childNode.nodeName === 'STYLE') {
          if (childNode.textContent) {
            childNode.textContent = childNode.textContent.replaceAll('width:100%', 'width:672px;height:192px');
            childNode.textContent = childNode.textContent.replaceAll('@media(', '@media(width:672px;height:192px;');
          }
        }
      });
    });
  }

  adjustShadowDomCss() {
    const min_width_672px = window.matchMedia('(min-width: 672px)');
    if (min_width_672px.matches) {
      const fiPrimaryOutcome = document.getElementById('fiPrimaryOutcome');
      if (fiPrimaryOutcome) fiPrimaryOutcome.style.width = '672px';

      // ddlPrimaryOutcome
      const goa_popover = document
        .querySelector('goa-dropdown#' + this.UiDefOutcomes.id)
        ?.shadowRoot?.querySelector('goa-popover');
      if (goa_popover) {
        // @ts-expect-error @typescript-eslint/ban-ts-comment
        goa_popover['maxwidth'] = '672px';

        const popover_target_container = goa_popover.shadowRoot?.querySelector('div');
        if (popover_target_container) {
          popover_target_container.style.width = '100%';

          const popover_target = popover_target_container.querySelector('div.popover-target');
          // @ts-expect-error @typescript-eslint/ban-ts-comment
          if (popover_target) popover_target['style']['width'] = '100%';
        }
      }
    }
  }

  private _adjustGoaDropdownStyle(fiId: string) {
    if (!fiId || fiId.length <= 0) return;
    console.log('_adjustGoaDropdownStyle called with: ' + fiId);

    const fiPrimaryOutcome: HTMLElement | null = document.getElementById(fiId);
    const goa_popover = document.querySelector(`#${fiId} goa-dropdown`)?.shadowRoot?.querySelector('goa-popover');

    if (!fiPrimaryOutcome || !goa_popover) {
      console.log('_adjustGoaDropdownStyle did not find elements for: ' + fiId);
    } else {
      fiPrimaryOutcome.style.width = '42rem';
      fiPrimaryOutcome.style.display = 'block';
      // @ts-expect-error @typescript-eslint/ban-ts-comment
      goa_popover['maxwidth'] = '42rem';
      const popover_target_container = goa_popover.shadowRoot?.querySelector('div');
      if (popover_target_container) {
        popover_target_container.style.width = '100%';

        const popover_target = popover_target_container.querySelector('div.popover-target');
        // @ts-expect-error @typescript-eslint/ban-ts-comment
        if (popover_target) popover_target['style']['width'] = '100%';
      }
    }
  }

  onFocusIn(UiDef: UiFieldCtrDef, setAsTouched = false) {
    console.log('focus in: ' + UiDef.nameCtr + ' setAsTouched= ' + setAsTouched);
    if (setAsTouched) {
      UiDef.formCtr.markAsTouched();
    }
    UiDef.focusedOutFieldByTrueBlurEvent = false;
    UiDef.focusedInNonBlankOrErrorField =
      (!!UiDef.errorMsg && UiDef.errorMsg.length > 0) ||
      CommUtilsService.isCtrValueNonBlank(UiDef.formCtr.value, UiDef.nameCtr);
  }

  onFocusOut(UiDef: UiFieldCtrDef, isBlurAway: boolean) {
    // console.log('focus out: ' + UiDef.nameCtr + ' value= ' + UiDef.formCtr.value + ' blur= ' + isBlurAway);
    if (isBlurAway) {
      UiDef.focusedOutFieldByTrueBlurEvent = true;
    }
    this.timeoutIds.push(
      setTimeout(() => {
        // Goa-input 1.16.0 has a bug, on blur, it fires:
        // 1. focusOut-event with null value first (even user picked an item in drop-down-list
        // 2. formControl-change-event with user-selected-valid-key after focusOut event
        // 3. old library fire focusOut-event with valid-ket last
        // to support both old and new library behaviour. I delayed the focusOut-event-process to avoid wrong-empty-key-error
        CommUtilsService.getUiFieldErrorList(UiDef, isBlurAway, 'focusOut');
      }, 400),
    );
  }

  hasGeneralInfoFormAnyError(): boolean {
    this.pageUiDefs.forEach((uiDef) => {
      CommUtilsService.getUiFieldErrorList(uiDef, true, 'pageCheckError');
    });

    return !this.generalInfoForm.valid;
  }

  get showErrorFieldsCallout(): boolean {
    // could not use this.projectsForm.invalid, since it include many Required error from ngInit!
    const baseHasError = this.pageUiDefs.findIndex((uiDef) => (uiDef.errorMsg ?? []).length > 0) >= 0;
    return baseHasError; // || otherCustomeErrors
  }

  override jumpToField(fieldName: string) {
    const fieldElement = document.getElementById(fieldName);
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      // window.scrollTo(0, fieldElement.offsetTop ?? 0);
    } else {
      console.error('Cannot find linked field: ' + fieldName);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  acceptPhoneNumberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return (
      (charCode >= 48 && charCode <= 57) || charCode == 45 // [0-9] or '-'
      // || (charCode == 40) || (charCode == 41) || (charCode == 32) // [(Space)]
    );
  }

  // fix goa-input touched bug: first-goa-input-element (in the page) after blur/focus-away,
  //    or click-in, click-away, touched flag is not set sometimes
  // fix goa-textarea touched bug: focus-in + blur/focus-away, touched flag is not set at all
  // fix goa-dropdown touched bug: focus-in + blur/focus-away, drop-list expand/shrink
  // without make a selection, touched flag is not set at all
  setCtrAsTouched(ctrName: string, eventType = '') {
    console.log(ctrName + eventType);
    this.generalInfoForm.controls[ctrName].markAsTouched();
  }

  // convert page UI-data into model, before send to back-end
  GetGeneralInfo(): Partial<ApplicationDraftRecordDto> {
    const uiValue = this.generalInfoForm.value;
    console.log('GetGeneralInfo', uiValue);

    return {
      name: uiValue[this.UiDefApplicationName.nameCtr] ?? '',
      primaryOutcomeId: parseInt(uiValue[this.UiDefOutcomes.nameCtr]) ?? 0,
      description: uiValue[this.UiDefDescription.nameCtr] ?? '',
      contactFirstName: uiValue[this.UiDefFirstName.nameCtr] ?? '',
      contactLastName: uiValue[this.UiDefLastName.nameCtr] ?? '',
      contactEmailAddress: uiValue[this.UiDefEmail.nameCtr] ?? '',
      contactPhoneNumber: uiValue[this.UiDefPhone.nameCtr] ?? '',
    };
  }

  override validateOnNext() {
    this.generalInfoForm.markAllAsTouched();

    this.pageUiDefs.forEach((uiDef) => {
      CommUtilsService.getUiFieldErrorList(uiDef, true, 'nextCheckError');
    });
    return this.generalInfoForm.valid;
  }

  override validateOnPrevious(): boolean {
    return true;
  }
}
