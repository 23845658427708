import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './features/home/home.component';
import { LandingComponent } from './features/landing/landing.component';
import { LogoutComponent } from './features/logout/logout.component';
import { AccessForbiddenComponent } from './features/error-pages/access-forbidden/access-forbidden.component';
import { NotFoundComponent } from './features/error-pages/notfound/notfound.component';
import { GrantApplicationComponent } from './features/grant-application/grant-application.component';
import { ViewApplicationsComponent } from './features/view-applications/view-applications.component';
import { AuthGuard } from './guard/auth.guard';
import { ApplicationSummaryComponent } from './features/view-applications/application-summary/application-summary.component';
import { ResourcesComponent } from './features/resources/resources.component';
import { GeneralErrorComponent } from './features/error-pages/general-error/general-error.component';
import { DraftApplicationsComponent } from './features/view-applications/draft-applications/draft-applications.component';
import { SubmittedApplicationsComponent } from './features/view-applications/submitted-applications/submitted-applications.component';
import { ReturnedApplicationsComponent } from './features/view-applications/returned-applications/returned-applications.component';
import { AcceptedApplicationsComponent } from './features/view-applications/accepted-applications/accepted-applications.component';
import { WithdrawnApplicationsComponent } from './features/view-applications/withdrawn-applications/withdrawn-applications.component';
import { AllocationsAndPaymentsComponent } from './features/allocations-and-payments/allocations-and-payments.component';
import { UnauthorizedComponent } from './features/error-pages/unauthorized/unauthorized.component';

const routes: Routes = [
  //{ path: 'grant-application', component: GrantApplicationComponent, canActivate: [AuthGuard] },
  { path: 'grant-application', component: GrantApplicationComponent, canActivate: [AuthGuard] },
  {
    path: 'view-applications',
    component: ViewApplicationsComponent,
    children: [
      {
        path: 'drafts',
        component: DraftApplicationsComponent,
      },
      {
        path: 'submitted',
        component: SubmittedApplicationsComponent,
      },
      {
        path: 'returned',
        component: ReturnedApplicationsComponent,
      },
      {
        path: 'accepted',
        component: AcceptedApplicationsComponent,
      },
      {
        path: 'withdrawn',
        component: WithdrawnApplicationsComponent,
      },
      {
        path: '',
        component: DraftApplicationsComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  { path: 'allocations-payments', component: AllocationsAndPaymentsComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'resources', component: ResourcesComponent, canActivate: [AuthGuard] },
  {
    path: 'application-summary/:status/:organizationId/:id',
    component: ApplicationSummaryComponent,
    canActivate: [AuthGuard],
  },
  { path: 'landing', component: LandingComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'access-forbidden', component: AccessForbiddenComponent, canActivate: [AuthGuard] },
  { path: 'general-error', component: GeneralErrorComponent },
  { path: 'page-not-found', component: NotFoundComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'page-not-found' },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
