/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ApplicationUserItemDto } from '../models/application-user-item-dto';
import { ExternalUserDto } from '../models/external-user-dto';
import { InternalUserDto } from '../models/internal-user-dto';
import { InternalUserItemDto } from '../models/internal-user-item-dto';
import { InternalUserUpdate } from '../models/internal-user-update';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tokenExpiry()` */
  static readonly TokenExpiryPath = '/api/auth/token-expiry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tokenExpiry()` instead.
   *
   * This method doesn't expect any request body.
   */
  tokenExpiry$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.TokenExpiryPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tokenExpiry$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tokenExpiry(
    params?: {
    },
    context?: HttpContext
  ): Observable<number> {
    return this.tokenExpiry$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getExternalProfile()` */
  static readonly GetExternalProfilePath = '/api/auth/external/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExternalProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalProfile$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ExternalUserDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.GetExternalProfilePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExternalUserDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExternalProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalProfile(
    params?: {
    },
    context?: HttpContext
  ): Observable<ExternalUserDto> {
    return this.getExternalProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalUserDto>): ExternalUserDto => r.body)
    );
  }

  /** Path part for operation `getInternalProfile()` */
  static readonly GetInternalProfilePath = '/api/auth/internal/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInternalProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternalProfile$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InternalUserDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.GetInternalProfilePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InternalUserDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInternalProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternalProfile(
    params?: {
    },
    context?: HttpContext
  ): Observable<InternalUserDto> {
    return this.getInternalProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<InternalUserDto>): InternalUserDto => r.body)
    );
  }

  /** Path part for operation `internalLogout()` */
  static readonly InternalLogoutPath = '/api/auth/internal/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `internalLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  internalLogout$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.InternalLogoutPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `internalLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  internalLogout(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.internalLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `logout()` */
  static readonly LogoutPath = '/api/auth/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logout()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.LogoutPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.logout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getGrantAdvisorList()` */
  static readonly GetGrantAdvisorListPath = '/api/auth/users/grant-advisors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGrantAdvisorList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGrantAdvisorList$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationUserItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.GetGrantAdvisorListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationUserItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGrantAdvisorList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGrantAdvisorList(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ApplicationUserItemDto>> {
    return this.getGrantAdvisorList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationUserItemDto>>): Array<ApplicationUserItemDto> => r.body)
    );
  }

  /** Path part for operation `getProgramManagerList()` */
  static readonly GetProgramManagerListPath = '/api/auth/users/program-managers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProgramManagerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProgramManagerList$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationUserItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.GetProgramManagerListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationUserItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProgramManagerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProgramManagerList(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ApplicationUserItemDto>> {
    return this.getProgramManagerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationUserItemDto>>): Array<ApplicationUserItemDto> => r.body)
    );
  }

  /** Path part for operation `getPaymentReviewerList()` */
  static readonly GetPaymentReviewerListPath = '/api/auth/users/payment-reviewers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentReviewerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentReviewerList$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationUserItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.GetPaymentReviewerListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationUserItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaymentReviewerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentReviewerList(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ApplicationUserItemDto>> {
    return this.getPaymentReviewerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationUserItemDto>>): Array<ApplicationUserItemDto> => r.body)
    );
  }

  /** Path part for operation `getPaymentAdminsList()` */
  static readonly GetPaymentAdminsListPath = '/api/auth/users/payment-admins';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentAdminsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentAdminsList$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationUserItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.GetPaymentAdminsListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationUserItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaymentAdminsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentAdminsList(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ApplicationUserItemDto>> {
    return this.getPaymentAdminsList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationUserItemDto>>): Array<ApplicationUserItemDto> => r.body)
    );
  }

  /** Path part for operation `getSystemAdminsList()` */
  static readonly GetSystemAdminsListPath = '/api/auth/users/system-admins';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSystemAdminsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSystemAdminsList$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationUserItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.GetSystemAdminsListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationUserItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSystemAdminsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSystemAdminsList(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ApplicationUserItemDto>> {
    return this.getSystemAdminsList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationUserItemDto>>): Array<ApplicationUserItemDto> => r.body)
    );
  }

  /** Path part for operation `getExpenditureOfficerList()` */
  static readonly GetExpenditureOfficerListPath = '/api/auth/users/expenditure-officers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExpenditureOfficerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExpenditureOfficerList$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationUserItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.GetExpenditureOfficerListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationUserItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExpenditureOfficerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExpenditureOfficerList(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ApplicationUserItemDto>> {
    return this.getExpenditureOfficerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationUserItemDto>>): Array<ApplicationUserItemDto> => r.body)
    );
  }

  /** Path part for operation `getAccountingOfficerList()` */
  static readonly GetAccountingOfficerListPath = '/api/auth/users/accounting-officers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountingOfficerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountingOfficerList$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationUserItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.GetAccountingOfficerListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationUserItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountingOfficerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountingOfficerList(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ApplicationUserItemDto>> {
    return this.getAccountingOfficerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationUserItemDto>>): Array<ApplicationUserItemDto> => r.body)
    );
  }

  /** Path part for operation `getInternalUsersList()` */
  static readonly GetInternalUsersListPath = '/api/auth/internal/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInternalUsersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternalUsersList$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<InternalUserItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.GetInternalUsersListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InternalUserItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInternalUsersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternalUsersList(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<InternalUserItemDto>> {
    return this.getInternalUsersList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InternalUserItemDto>>): Array<InternalUserItemDto> => r.body)
    );
  }

  /** Path part for operation `getInternalUser()` */
  static readonly GetInternalUserPath = '/api/auth/internal/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInternalUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternalUser$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InternalUserItemDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.GetInternalUserPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InternalUserItemDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInternalUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternalUser(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<InternalUserItemDto> {
    return this.getInternalUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<InternalUserItemDto>): InternalUserItemDto => r.body)
    );
  }

  /** Path part for operation `updateInternalUser()` */
  static readonly UpdateInternalUserPath = '/api/auth/internal/users/update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInternalUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInternalUser$Response(
    params: {
      id: string;
      body: InternalUserUpdate
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InternalUserItemDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.UpdateInternalUserPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InternalUserItemDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInternalUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInternalUser(
    params: {
      id: string;
      body: InternalUserUpdate
    },
    context?: HttpContext
  ): Observable<InternalUserItemDto> {
    return this.updateInternalUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<InternalUserItemDto>): InternalUserItemDto => r.body)
    );
  }

  /** Path part for operation `disableInternalUser()` */
  static readonly DisableInternalUserPath = '/api/auth/internal/users/disable/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disableInternalUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  disableInternalUser$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InternalUserItemDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.DisableInternalUserPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InternalUserItemDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disableInternalUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disableInternalUser(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<InternalUserItemDto> {
    return this.disableInternalUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<InternalUserItemDto>): InternalUserItemDto => r.body)
    );
  }

  /** Path part for operation `getAvailableMgoSystemAdminActions()` */
  static readonly GetAvailableMgoSystemAdminActionsPath = '/api/auth/internal/admin/available-actions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAvailableMGOSystemAdminActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAvailableMGOSystemAdminActions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.GetAvailableMgoSystemAdminActionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetAvailableMGOSystemAdminActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAvailableMGOSystemAdminActions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.GetAvailableMGOSystemAdminActions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

}
