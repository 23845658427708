import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  SetCurrentApplicationDraftId,
  SetCurrentOrganizationId,
  SetCurrentApplicationDraftStep,
  ClearContextOnLogout,
  ClearApplicationDraftContext,
} from '../actions/current-context.action';
import { Injectable } from '@angular/core';
import { FormSequence } from '@app-pot/features/grant-application/models/enums';

export interface CurrentContextStateModel {
  currentOrganizationId: number;
  currentApplicationDraftId?: number;
  currentApplicationDraftStep?: FormSequence;
}

@State<CurrentContextStateModel>({
  name: 'current_context',
})
@Injectable()
export class CurrentContextState {
  @Selector()
  static getCurrentOrganizationId({ currentOrganizationId }: CurrentContextStateModel) {
    return currentOrganizationId;
  }

  @Selector()
  static getCurrentApplicationDraftId({ currentApplicationDraftId }: CurrentContextStateModel) {
    return currentApplicationDraftId;
  }

  @Selector()
  static getCurrentApplicationDraftStep({ currentApplicationDraftStep }: CurrentContextStateModel) {
    return currentApplicationDraftStep;
  }

  @Action(SetCurrentOrganizationId)
  setOrganizationId({ patchState }: StateContext<CurrentContextStateModel>, { payload }: SetCurrentOrganizationId) {
    patchState({ currentOrganizationId: payload });
  }

  @Action(SetCurrentApplicationDraftId)
  setCurrentApplicationDraftId(
    { patchState }: StateContext<CurrentContextStateModel>,
    { payload }: SetCurrentApplicationDraftId,
  ) {
    patchState({ currentApplicationDraftId: payload });
  }

  @Action(SetCurrentApplicationDraftStep)
  setCurrentApplicationDraftStep(
    { patchState }: StateContext<CurrentContextStateModel>,
    { payload }: SetCurrentApplicationDraftStep,
  ) {
    patchState({ currentApplicationDraftStep: payload });
  }

  @Action(ClearContextOnLogout)
  clearContextOnLogout({ patchState }: StateContext<CurrentContextStateModel>) {
    patchState({
      currentOrganizationId: 0,
      currentApplicationDraftId: 0,
      currentApplicationDraftStep: FormSequence.GeneralInfo,
    });
  }

  @Action(ClearApplicationDraftContext)
  clearApplicationDraftContext({ patchState }: StateContext<CurrentContextStateModel>) {
    patchState({ currentApplicationDraftId: 0, currentApplicationDraftStep: FormSequence.GeneralInfo });
  }
}
