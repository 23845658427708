<form [formGroup]="projectsForm" [class.popup-form-container]="mode === 'EDIT'">
  <div class="mb-56px" *ngIf="showErrorFieldsCallout">
    <goa-callout
      type="emergency"
      heading="{{ 'errorCalloutHeading' | res: 'COMMON' }}"
      *ngIf="showErrorFieldsCallout"
      [id]="calloutWrapId"
    >
      <ng-container *ngFor="let uiDef of pageUiDefs">
        <a *ngIf="uiDef.errorMsg" (click)="jumpToField(uiDef.idWrap ?? '')" class="validation-message-link"
          >{{ uiDef.labelText }}<br
        /></a>
      </ng-container>

      <!-- show extra custom error, and prevent duplicate field-label showing -->
      <a
        *ngIf="
          (UiDefEstimatedTotalCost.errorMsg || shouldShowcombinedFundingVsEstimatedAmountValidationMessage) &&
          !UiDefEstimatedTotalCost.errorMsg
        "
        (click)="jumpToField(UiDefEstimatedTotalCost.id + '-wrap')"
        class="validation-message-link"
        >{{ UiDefEstimatedTotalCost.labelText }}<br
      /></a>

      <a
        *ngIf="
          (UiDefLgffFundingAmountRequested.errorMsg ||
            shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
            shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage) &&
          !UiDefLgffFundingAmountRequested.errorMsg
        "
        (click)="jumpToField(UiDefLgffFundingAmountRequested.id + '-wrap')"
        class="validation-message-link"
        >{{ UiDefLgffFundingAmountRequested.labelText }}<br
      /></a>

      <a
        *ngIf="
          (UiDefFundingFromOtherGrantPrograms.errorMsg ||
            shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
            shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage ||
            shouldShowfundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage) &&
          !UiDefFundingFromOtherGrantPrograms.errorMsg
        "
        (click)="jumpToField(UiDefFundingFromOtherGrantPrograms.id + '-wrap')"
        class="validation-message-link"
        >{{ UiDefFundingFromOtherGrantPrograms.labelText }}<br
      /></a>

      <a
        *ngIf="shouldShowInvalidAddressLocationMessage"
        (click)="jumpToField(locationStartSpotId)"
        class="validation-message-link"
        >Project location<br
      /></a>
    </goa-callout>
  </div>
  <div class="fi-container-pb dropdown-width-32" *ngIf="UiDefProjectName; let UiDef">
    <goa-form-item [attr.id]="UiDef.id + '-wrap'" [label]="UiDef.labelText">
      <goa-textarea
        type="text"
        width="100%"
        focus="true"
        name="{{ UiDef.name }}"
        id="{{ UiDef.id }}"
        [placeholder]="UiDef.placeholder"
        [error]="UiDef.errorMsg"
        formControlName="{{ UiDef.nameCtr }}"
        goaValue
        countby="character"
        showcount="true"
        rows="2"
        [value]="UiDef.formCtr.value"
        maxcount="{{ UiDef.maxLength }}"
        (focusin)="onFocusIn(UiDef)"
        (focusout)="onFocusOut(UiDef, true)"
      >
      </goa-textarea>
      <div *ngIf="UiDef.errorMsg" class="error-msg">
        <div *ngFor="let error of UiDef.errorMsg; index as errNum">
          <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
            <goa-icon type="warning" size="small" theme="filled" />
            {{ error }}
          </div>
        </div>
      </div>
    </goa-form-item>
    <div class="help-msg-prj" [id]="UiDef.id + '-help'" [attr.data-automation-id]="UiDef.id + '-help'">
      {{ UiDef.helptext }}
    </div>
  </div>

  <div class="fi-container-pb project-description-width" *ngIf="UiDefProjectDescription; let UiDef">
    <goa-form-item [attr.id]="UiDef.id + '-wrap'" [label]="UiDef.labelText">
      <goa-textarea
        type="text"
        width="100%"
        name="{{ UiDef.name }}"
        id="{{ UiDef.id }}"
        [placeholder]="UiDef.placeholder"
        [error]="UiDef.errorMsg"
        formControlName="{{ UiDef.nameCtr }}"
        goaValue
        [value]="UiDef.formCtr.value"
        (focusin)="onFocusIn(UiDef)"
        (focusout)="onFocusOut(UiDef, true)"
        maxcount="250"
        countby="character"
        showcount="false"
      >
      </goa-textarea>

      <div *ngIf="UiDef.errorMsg" class="error-msg">
        <div *ngFor="let error of UiDef.errorMsg; index as errNum">
          <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
            <goa-icon type="warning" size="small" theme="filled" />
            {{ error }}
          </div>
        </div>
      </div>
    </goa-form-item>
    <div class="help-msg-prj" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
  </div>

  <!-- item id="fiPrimaryCapitalAsset" -->
  <div class="fi-container-pb dropdown-width-32" *ngIf="UiDefPrimaryCapitalAsset; let UiDef">
    <goa-form-item [attr.id]="UiDef.id + '-wrap'" [label]="UiDef.labelText">
      <goa-dropdown
        width="32rem"
        relative="true"
        name="{{ UiDef.name }}"
        [attr.id]="UiDef.id"
        [placeholder]="UiDef.placeholder"
        [error]="UiDef.errorMsg"
        formControlName="{{ UiDef.nameCtr }}"
        goaValue
        [value]="UiDef.formCtr.value"
        (focusin)="onFocusIn(UiDef, true)"
        (focusout)="onFocusOut(UiDef, true)"
      >
        <ng-container *ngFor="let capitalAssetType of capitalAssetTypes$ | async">
          <goa-dropdown-item [value]="capitalAssetType.id" [label]="capitalAssetType.title"></goa-dropdown-item>
        </ng-container>
      </goa-dropdown>
      <div *ngIf="UiDef.errorMsg" class="error-msg">
        <div *ngFor="let error of UiDef.errorMsg; index as errNum">
          <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
            <goa-icon type="warning" size="small" theme="filled" />
            {{ error }}
          </div>
        </div>
      </div>
    </goa-form-item>
    <div class="help-msg-prj" [id]="UiDef.id + '-help'" [attr.data-automation-id]="'primary-capital-asset-help-msg'">
      {{ UiDef.helptext }}
    </div>
  </div>

  <div
    class="fi-container-pb dropdown-width-32"
    style="padding-top: -8px"
    *ngIf="UiDefAdditionalCapitalAssets; let UiDef"
  >
    <div class="label" data-automation-id="additional-capital-assets-label">
      {{ UiDef.labelText }} <span class="label-optional">(optional)</span>
    </div>
    <mat-form-field class="additional-capital-asset" [attr.id]="UiDef.id">
      <mat-label>Please select one or more assets</mat-label>
      <mat-select [formControl]="UiDef.formCtr" multiple>
        <mat-select-trigger>
          <ng-container *ngIf="UiDef.formCtr.value?.length > 1; else singleOption"
            >{{ UiDef.formCtr.value.length }} options selected</ng-container
          >
          <ng-template #singleOption>1 option selected</ng-template>
        </mat-select-trigger>
        <mat-option
          [attr.data-automation-id]="UiDef.id + '-option-' + additionalCapitalAssetType.id"
          *ngFor="let additionalCapitalAssetType of additionalCapitalAssetTypes"
          [value]="additionalCapitalAssetType.id"
          >{{ additionalCapitalAssetType.title }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <div
      class="help-msg-prj"
      [id]="UiDef.id + '-help'"
      [attr.data-automation-id]="'additional-capital-assets-help-msg'"
    >
      {{ UiDef.helptext }}
    </div>
  </div>

  <goa-divider mb="xl"></goa-divider>

  <div *ngIf="isMapVisible" [ngClass]="{ 'error-red-border': shouldShowInvalidAddressLocationMessage }">
    <app-googlemap
      [formMode]="this.mode"
      [locationDetails]="
        this.mode == 'EDIT' && project
          ? {
              address: project.address ?? '',
              coord: { lat: project.latitude!, lng: project.longitude! }
            }
          : undefined
      "
      (populateAddress)="populateAddress($event)"
      [isMapVisible]="isMapVisible"
      [selectedProjectLocation]="project ? { latitude: project.latitude!, longitude: project.longitude! } : undefined"
    >
    </app-googlemap>
  </div>
  <div *ngIf="isMapVisible && shouldShowInvalidAddressLocationMessage" class="long-error-msg">
    <div [id]="locationErrorMessageId">
      <goa-icon type="warning" size="small" theme="filled" />
      {{ emptyAddrsssLocationMessage }}
    </div>
  </div>

  <!-- <goa-divider mb="xl"></goa-divider> -->
  <!-- 3-address inputs -->

  <goa-divider mt="xl" mb="xl"></goa-divider>
  <ng-container formGroupName="projectTypeDetails">
    <div class="fi-container-pb input-width" *ngIf="UiDefProjectType; let UiDef">
      <goa-form-item [attr.id]="UiDef.id + '-wrap'" [label]="UiDef.labelText">
        <goa-dropdown
          width="32rem"
          relative="true"
          name="{{ UiDef.name }}"
          [attr.id]="UiDef.id"
          [placeholder]="UiDef.placeholder"
          [error]="UiDef.errorMsg"
          formControlName="{{ UiDef.nameCtr }}"
          goaValue
          [value]="UiDef.formCtr.value"
          (focusin)="onFocusIn(UiDef, true)"
          (focusout)="onFocusOut(UiDef, true)"
        >
          <goa-dropdown-item value="New" [label]="applicationProjectType.New"></goa-dropdown-item>
          <goa-dropdown-item value="Upgrade" [label]="applicationProjectType.Upgrade"></goa-dropdown-item>
          <goa-dropdown-item value="Both" [label]="applicationProjectType.Both"></goa-dropdown-item>
        </goa-dropdown>
        <div *ngIf="UiDef.errorMsg" class="error-msg">
          <div *ngFor="let error of UiDef.errorMsg; index as errNum">
            <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ error }}
            </div>
          </div>
        </div>
      </goa-form-item>
      <div class="help-msg-prj" [id]="UiDef.id + '-help'" [attr.data-automation-id]="UiDef.id + '-help'">
        {{ UiDef.helptext }}
      </div>
    </div>

    <div class="multi-fi-container">
      <ng-container
        *ngIf="
          projectType && (projectType === applicationProjectType.Both || projectType === applicationProjectType.New)
        "
      >
        <div class="fi-container-pb qty-new-upgrade" *ngIf="UiDefQuantityNew; let UiDef">
          <goa-form-item [attr.id]="UiDef.id + '-wrap'" [label]="UiDef.labelText">
            <goa-input
              type="text"
              width="100%"
              positiveDecimal
              name="{{ UiDef.name }}"
              id="{{ UiDef.id }}"
              [placeholder]="UiDef.placeholder"
              [error]="UiDef.errorMsg"
              formControlName="{{ UiDef.nameCtr }}"
              goaValue
              [value]="UiDef.formCtr.value"
              maxlength="{{ UiDef.maxLength }}"
              (focusin)="onFocusIn(UiDef)"
              (focusout)="onFocusOut(UiDef, true)"
            >
              <div slot="trailingContent" class="capital-asset-measurement-unit">{{ capitalAssetMeasurementUnit }}</div>
            </goa-input>
            <div *ngIf="UiDef.errorMsg" class="error-msg">
              <div *ngFor="let error of UiDef.errorMsg; index as errNum">
                <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                  <goa-icon type="warning" size="small" theme="filled" />
                  {{ error }}
                </div>
              </div>
            </div>
          </goa-form-item>
          <div class="help-msg-prj-quantity" [id]="UiDef.id + '-help'" [attr.data-automation-id]="UiDef.id + '-help'">
            {{ UiDef.helptext }}
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          projectType && (projectType === applicationProjectType.Both || projectType === applicationProjectType.Upgrade)
        "
      >
        <div class="fi-container-pb" *ngIf="UiDefQuantityUpgrade; let UiDef">
          <div class="qty-new-upgrade">
            <goa-form-item [attr.id]="UiDef.id + '-wrap'" [label]="UiDef.labelText">
              <goa-input
                type="text"
                width="100%"
                positiveDecimal
                name="{{ UiDef.name }}"
                id="{{ UiDef.id }}"
                [placeholder]="UiDef.placeholder"
                [error]="UiDef.errorMsg"
                formControlName="{{ UiDef.nameCtr }}"
                goaValue
                [value]="UiDef.formCtr.value"
                maxlength="{{ UiDef.maxLength }}"
                (focusin)="onFocusIn(UiDef)"
                (focusout)="onFocusOut(UiDef, true)"
              >
                <div slot="trailingContent" class="capital-asset-measurement-unit">
                  {{ capitalAssetMeasurementUnit }}
                </div>
              </goa-input>
              <div *ngIf="UiDef.errorMsg" class="error-msg">
                <div *ngFor="let error of UiDef.errorMsg; index as errNum">
                  <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                    <goa-icon type="warning" size="small" theme="filled" />
                    {{ error }}
                  </div>
                </div>
              </div>
            </goa-form-item>
          </div>
          <div class="help-msg-prj-quantity" [id]="UiDef.id + '-help'" [attr.data-automation-id]="UiDef.id + '-help'">
            {{ UiDef.helptext }}
          </div>
        </div>
      </ng-container>
    </div>

    <div class="multi-fi-container">
      <ng-container>
        <div
          class="fi-container-pb asset-condition-ranking-fi-container"
          *ngIf="UiDefCurrentAssetRanking; let UiDef"
          [hidden]="needHideAssetRanking()"
        >
          <goa-form-item [attr.id]="UiDef.id + '-wrap'" [label]="UiDef.labelText">
            <goa-dropdown
              width="32rem"
              relative="true"
              name="{{ UiDef.name }}"
              [attr.id]="UiDef.id"
              [placeholder]="UiDef.placeholder"
              [error]="UiDef.errorMsg"
              formControlName="{{ UiDef.nameCtr }}"
              goaValue
              [value]="UiDef.formCtr.value"
              (focusin)="onFocusIn(UiDef, true)"
              (focusout)="onFocusOut(UiDef, true)"
            >
              <goa-dropdown-item
                *ngFor="let assetConditionRanking of assetConditionRankings$ | async"
                [value]="assetConditionRanking.id"
                [label]="
                  assetConditionRanking.id +
                  '. ' +
                  assetConditionRanking.title +
                  ': ' +
                  assetConditionRanking.description
                "
              >
              </goa-dropdown-item>
            </goa-dropdown>
            <div *ngIf="UiDef.errorMsg" class="error-msg">
              <div *ngFor="let error of UiDef.errorMsg; index as errNum">
                <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                  <goa-icon type="warning" size="small" theme="filled" />
                  {{ error }}
                </div>
              </div>
            </div>
          </goa-form-item>
          <div class="help-msg-prj" [id]="UiDef.id + '-help'" [attr.data-automation-id]="UiDef.id + '-help'">
            {{ UiDef.helptext }}
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div class="d-flex">
    <div class="multi-fi-container" style="margin-right: 2rem" *ngIf="UiDefAnticipatedStartDate; let UiDef">
      <div class="fi-container-pb start-end-date-width">
        <goa-form-item [attr.id]="UiDef.id + '-wrap'" [label]="UiDef.labelText">
          <goa-input
            type="date"
            width="100%"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
          >
          </goa-input>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
        <div class="help-msg-prj-quantity" [id]="UiDef.id + '-help'" [attr.data-automation-id]="UiDef.id + '-help'">
          {{ UiDef.helptext }}
        </div>
      </div>
    </div>

    <div class="multi-fi-container" *ngIf="UiDefAnticipatedEndDate; let UiDef">
      <div class="fi-container-pb start-end-date-width">
        <goa-form-item [attr.id]="UiDef.id + '-wrap'" [label]="UiDef.labelText">
          <goa-input
            type="date"
            width="100%"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
          >
          </goa-input>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
        <div class="help-msg-prj-quantity" [id]="UiDef.id + '-help'" [attr.data-automation-id]="UiDef.id + '-help'">
          {{ UiDef.helptext }}
        </div>
      </div>
    </div>
  </div>

  <div class="fi-container-pb" *ngIf="UiDefEstimatedTotalCost; let UiDef">
    <div class="currency-input-width">
      <goa-form-item [attr.id]="UiDef.id + '-wrap'" [label]="UiDef.labelText">
        <goa-input
          type="text"
          width="100%"
          commaFormatNumber
          [allowZero]="false"
          name="{{ UiDef.name }}"
          id="{{ UiDef.id }}"
          [placeholder]="UiDef.placeholder"
          [error]="UiDef.errorMsg || shouldShowcombinedFundingVsEstimatedAmountValidationMessage"
          formControlName="{{ UiDef.nameCtr }}"
          goaValue
          [value]="UiDef.formCtr.value"
          maxlength="{{ UiDef.maxLength }}"
          (focusin)="onFocusIn(UiDef)"
          (focusout)="onFocusOut(UiDef, true)"
        >
          <div slot="leadingContent">
            <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
          </div>
        </goa-input>
        <div
          *ngIf="UiDef.errorMsg || shouldShowcombinedFundingVsEstimatedAmountValidationMessage"
          class="error-msg amount-error-msg"
        >
          <div *ngFor="let error of UiDef.errorMsg; index as errNum">
            <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ error }}
            </div>
          </div>
          <div *ngIf="shouldShowcombinedFundingVsEstimatedAmountValidationMessage" class="long-error-msg">
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ combinedFundingVsEstimatedAmountValidationMessage }}
            </div>
          </div>
        </div>
      </goa-form-item>
      <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
    </div>
  </div>

  <div class="fi-container-pb" *ngIf="UiDefLgffFundingAmountRequested; let UiDef">
    <div class="label" [attr.data-automation-id]="UiDef.id + '-label'">{{ UiDef.labelText }}</div>
    <div class="currency-input-width">
      <goa-form-item [attr.id]="UiDef.id + '-wrap'">
        <goa-input
          type="text"
          width="100%"
          commaFormatNumber
          [allowZero]="false"
          name="{{ UiDef.name }}"
          id="{{ UiDef.id }}"
          [placeholder]="UiDef.placeholder"
          [error]="
            UiDef.errorMsg ||
            shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
            shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage
          "
          formControlName="{{ UiDef.nameCtr }}"
          goaValue
          [value]="UiDef.formCtr.value"
          maxlength="{{ UiDef.maxLength }}"
          (focusin)="onFocusIn(UiDef)"
          (focusout)="onFocusOut(UiDef, true)"
        >
          <div slot="leadingContent">
            <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
          </div>
        </goa-input>
        <div
          *ngIf="
            UiDef.errorMsg ||
            shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
            shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage
          "
        >
          <div *ngFor="let error of UiDef.errorMsg; index as errNum" class="error-msg amount-error-msg">
            <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ error }}
            </div>
          </div>
          <div *ngIf="shouldShowcombinedFundingVsEstimatedAmountValidationMessage" class="long-error-msg">
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ combinedFundingVsEstimatedAmountValidationMessage }}
            </div>
          </div>
          <div
            *ngIf="!submitClicked && shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage"
            class="long-error-msg"
          >
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ lgffFundingAmountRequestedVsEstimatedAmountValidationMessage }}
            </div>
          </div>
        </div>
      </goa-form-item>
    </div>
    <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
  </div>

  <div class="fi-container-pb" *ngIf="hasOtherSourcesOfFunding && UiDefFundingFromOtherGrantPrograms; let UiDef">
    <div class="label" [attr.data-automation-id]="UiDef.id + '-label'">{{ UiDef.labelText }}</div>
    <div class="currency-input-width">
      <goa-form-item [attr.id]="UiDef.id + '-wrap'">
        <goa-input
          type="text"
          width="100%"
          commaFormatNumber
          name="{{ UiDef.name }}"
          id="{{ UiDef.id }}"
          [placeholder]="UiDef.placeholder"
          [error]="
            UiDef.errorMsg ||
            shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
            shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage ||
            shouldShowfundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage
          "
          formControlName="{{ UiDef.nameCtr }}"
          goaValue
          [value]="UiDef.formCtr.value"
          maxlength="{{ UiDef.maxLength }}"
          (focusin)="onFocusIn(UiDef)"
          (focusout)="onFocusOut(UiDef, true)"
        >
          <div slot="leadingContent">
            <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
          </div>
        </goa-input>
        <div
          *ngIf="
            UiDef.errorMsg ||
            shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
            shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage ||
            shouldShowfundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage
          "
        >
          <div *ngFor="let error of UiDef.errorMsg; index as errNum" class="error-msg amount-error-msg">
            <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ error }}
            </div>
          </div>
          <div *ngIf="shouldShowcombinedFundingVsEstimatedAmountValidationMessage" class="long-error-msg">
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ combinedFundingVsEstimatedAmountValidationMessage }}
            </div>
          </div>
          <div
            *ngIf="!submitClicked && shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage"
            class="long-error-msg"
          >
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ lgffFundingAmountRequestedVsEstimatedAmountValidationMessage }}
            </div>
          </div>
          <div
            *ngIf="!submitClicked && shouldShowfundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage"
            class="long-error-msg"
          >
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ fundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage }}
            </div>
          </div>
        </div>
      </goa-form-item>
    </div>
    <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
  </div>

  <div class="fi-container-pb" *ngIf="UiDefFundingFromMunicipalSources; let UiDef">
    <div class="label" [attr.data-automation-id]="UiDef.id + '-label'">{{ UiDef.labelText }}</div>
    <div class="currency-input-width">
      <goa-form-item [attr.id]="UiDef.id + '-wrap'">
        <goa-input
          type="text"
          width="100%"
          commaFormatNumber
          [attr.disabled]="true"
          name="{{ UiDef.name }}"
          id="{{ UiDef.id }}"
          [placeholder]="UiDef.placeholder"
          [error]="false"
          formControlName="{{ UiDef.nameCtr }}"
          goaValue
          [value]="UiDef.formCtr.value"
          maxlength="{{ UiDef.maxLength }}"
          (focusin)="onFocusIn(UiDef)"
          (focusout)="onFocusOut(UiDef, true)"
        >
          <div slot="leadingContent">
            <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
          </div>
        </goa-input>
      </goa-form-item>
    </div>
    <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
  </div>

  <div *ngIf="mode === 'ADD'">
    <goa-button type="secondary" (click)="addToList()">Add</goa-button>
    <goa-button
      type="tertiary"
      style="padding-top: 16px; margin-left: 16px; width: 134px"
      id="clearProjectForm"
      (click)="clearProjectForm()"
      >Clear project</goa-button
    >
  </div>
</form>
