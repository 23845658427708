/* tslint:disable */
/* eslint-disable */
export enum PaymentBatchStageType {
  New = 'New',
  UnderReview = 'UnderReview',
  EoRecommendation = 'EORecommendation',
  AoApproval = 'AOApproval',
  PaymentInProgress = 'PaymentInProgress',
  PaidReconciled = 'PaidReconciled',
  PartiallyReconciled = 'PartiallyReconciled',
  PartiallyReconciledWithError = 'PartiallyReconciledWithError',
  Cancelled = 'Cancelled'
}
