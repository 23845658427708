import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LgffGoaTablePaginationComponent } from '../lgff-goa-table-pagination/lgff-goa-table-pagination.component';
import { FrontEndVM } from './frontend.vm';

@Component({
  selector: 'app-lgff-goa-table',
  templateUrl: './lgff-goa-table.component.html',
  styleUrl: './lgff-goa-table.component.scss',
})
export class LgffGoaTableComponent implements OnChanges {
  pageId = 'COMMON';
  @Output() sortClicked: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() perPageChanged: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() pageChanged: EventEmitter<Event> = new EventEmitter<Event>();

  @ViewChild(LgffGoaTablePaginationComponent) lgffGoaTablePagination: LgffGoaTablePaginationComponent;

  @Input() tableId = '';
  @Input() resPageId = 'COMMON';
  @Input() colDef: LgffGoADisplayedColumnDef[];
  @Input() tableData: FrontEndVM[];
  @Input() totalCount = 0;
  @Input() currentPage = 1;
  @Input() perPageCount = 25;
  @Input() pageSizeOptions = [10, 25, 50, 100];
  @Input() displayPaginator = true;
  @Input() showCheckboxes = false;

  headerAllChecked = false;
  lgffGoaTableFormGroup: FormGroup;
  checkedItems: Set<number> = new Set<number>();

  constructor(private formBuilder: FormBuilder) {
    this.lgffGoaTableFormGroup = this.formBuilder.group({});
    this.configBatchCheckboxFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tableData']) {
      this.configBatchCheckboxFormGroup();
    }
  }

  configBatchCheckboxFormGroup() {
    if (!this.showCheckboxes) {
      return;
    }
    this.tableData.forEach((vm) => {
      this.lgffGoaTableFormGroup.addControl(vm['id'] + '', new FormControl(false));
    });
  }

  getFormControl(controlName: string): FormControl {
    return (this.lgffGoaTableFormGroup.get(controlName) as FormControl) ?? {};
  }

  clearAllCheckboxes() {
    const evt = new CustomEvent('clearCheckboxes', { detail: { value: '' } });
    this.onHeaderCheckboxChange(evt);
  }

  onHeaderCheckboxChange(event: Event) {
    const e = event as CustomEvent;
    this.headerAllChecked = e.detail.value == 'checked' ? true : false;
    if (this.headerAllChecked) {
      this.tableData.forEach((vm) => {
        this.checkedItems.add(vm['id']);
      });
    } else {
      this.checkedItems.clear();
    }
    this.tableData.forEach((vm) => {
      vm['batchChecked'] = e.detail.checked;
      this.lgffGoaTableFormGroup.get(vm['id'] + '')?.setValue(e.detail.value);
    });
  }

  onCheckboxChange(event: Event) {
    const e = event as CustomEvent;
    const recApp = this.tableData.find((vm) => {
      return vm['id'] + '' == e.detail.name;
    });
    if (!e.detail.value) {
      this.headerAllChecked = false;
      if (recApp) {
        recApp['batchChecked'] = false;
      }
      this.checkedItems.delete(+e.detail.name);
    } else {
      if (recApp) {
        recApp['batchChecked'] = true;
      }
      this.checkedItems.add(+e.detail.name);
    }
    this.lgffGoaTableFormGroup.get(e.detail.name)?.setValue(e.detail.value);
  }

  handleSort(event: Event) {
    this.sortClicked.emit(event);
  }

  handlePerPageChange(event: Event) {
    this.perPageChanged.emit(event);
  }

  handlePageChange(event: Event) {
    this.pageChanged.emit(event);
  }

  informPagination(event: Event) {
    if (!this.lgffGoaTablePagination) {
      return;
    }
    this.lgffGoaTablePagination.pageNumber = (event as CustomEvent).detail.page;
  }
}

export class LgffGoADisplayedColumnDef {
  fieldName: string;
  displayName?: string;
  sortable?: boolean;
  sortName?: string;
  sortDir?: 'asc' | 'desc';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columnTemplate?: TemplateRef<any> | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCellClicked?: (element: any) => void | undefined;
  headerClass?: string;
  cellClass?: string;
  misc?: object;

  constructor(
    fieldName: string,
    displayName = '',
    sortable = false,
    sortName = '',
    sortDir: 'asc' | 'desc',
    columnTemplate = null,
    onCellClicked = undefined,
    headerClass = '',
    cellClass = '',
    misc = {},
  ) {
    if (!fieldName || !fieldName.trim()) {
      throw Error('Please provide fieldName');
    }
    this.fieldName = fieldName;
    this.displayName = displayName ? displayName : fieldName;
    this.sortable = sortable;
    this.sortName = sortName ? sortName : fieldName;
    this.sortDir = sortDir ? sortDir : 'asc';
    this.columnTemplate = columnTemplate;
    this.onCellClicked = onCellClicked;
    this.headerClass = headerClass;
    this.cellClass = cellClass;
    this.misc = misc;
  }
}
