/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ApplicationBatchStageTypeDto } from '../models/application-batch-stage-type-dto';
import { ApplicationRecordLookupSetDto } from '../models/application-record-lookup-set-dto';
import { ApplicationStageTypeDto } from '../models/application-stage-type-dto';
import { ApplicationStatusTypeDto } from '../models/application-status-type-dto';
import { AssetConditionRankingDto } from '../models/asset-condition-ranking-dto';
import { CapitalAssetTypeDto } from '../models/capital-asset-type-dto';
import { FunctionalCategoryTypeDto } from '../models/functional-category-type-dto';
import { PrimaryOutcomeDto } from '../models/primary-outcome-dto';

@Injectable({ providedIn: 'root' })
export class LookupValueService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `findAllApplicationRecordLookupSet()` */
  static readonly FindAllApplicationRecordLookupSetPath = '/api/lookup-values/application-record-lookup-set';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllApplicationRecordLookupSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllApplicationRecordLookupSet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationRecordLookupSetDto>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindAllApplicationRecordLookupSetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationRecordLookupSetDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllApplicationRecordLookupSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllApplicationRecordLookupSet(
    params?: {
    },
    context?: HttpContext
  ): Observable<ApplicationRecordLookupSetDto> {
    return this.findAllApplicationRecordLookupSet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationRecordLookupSetDto>): ApplicationRecordLookupSetDto => r.body)
    );
  }

  /** Path part for operation `findAllAssetConditionRankings()` */
  static readonly FindAllAssetConditionRankingsPath = '/api/lookup-values/asset-condition-rankings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllAssetConditionRankings()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllAssetConditionRankings$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AssetConditionRankingDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindAllAssetConditionRankingsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AssetConditionRankingDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllAssetConditionRankings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllAssetConditionRankings(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AssetConditionRankingDto>> {
    return this.findAllAssetConditionRankings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AssetConditionRankingDto>>): Array<AssetConditionRankingDto> => r.body)
    );
  }

  /** Path part for operation `findAllCapitalAssetTypes()` */
  static readonly FindAllCapitalAssetTypesPath = '/api/lookup-values/capital-asset-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllCapitalAssetTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCapitalAssetTypes$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CapitalAssetTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindAllCapitalAssetTypesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CapitalAssetTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllCapitalAssetTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCapitalAssetTypes(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<CapitalAssetTypeDto>> {
    return this.findAllCapitalAssetTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CapitalAssetTypeDto>>): Array<CapitalAssetTypeDto> => r.body)
    );
  }

  /** Path part for operation `findAllFunctionalCategoryTypes()` */
  static readonly FindAllFunctionalCategoryTypesPath = '/api/lookup-values/functional-category-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllFunctionalCategoryTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllFunctionalCategoryTypes$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FunctionalCategoryTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindAllFunctionalCategoryTypesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FunctionalCategoryTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllFunctionalCategoryTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllFunctionalCategoryTypes(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<FunctionalCategoryTypeDto>> {
    return this.findAllFunctionalCategoryTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FunctionalCategoryTypeDto>>): Array<FunctionalCategoryTypeDto> => r.body)
    );
  }

  /** Path part for operation `findAllPrimaryOutcomes()` */
  static readonly FindAllPrimaryOutcomesPath = '/api/lookup-values/primary-outcomes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllPrimaryOutcomes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllPrimaryOutcomes$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PrimaryOutcomeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindAllPrimaryOutcomesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PrimaryOutcomeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllPrimaryOutcomes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllPrimaryOutcomes(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<PrimaryOutcomeDto>> {
    return this.findAllPrimaryOutcomes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PrimaryOutcomeDto>>): Array<PrimaryOutcomeDto> => r.body)
    );
  }

  /** Path part for operation `getApplicationStatuses()` */
  static readonly GetApplicationStatusesPath = '/api/lookup-values/application-statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationStatuses$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationStatusTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.GetApplicationStatusesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationStatusTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationStatuses(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ApplicationStatusTypeDto>> {
    return this.getApplicationStatuses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationStatusTypeDto>>): Array<ApplicationStatusTypeDto> => r.body)
    );
  }

  /** Path part for operation `getApplicationStages()` */
  static readonly GetApplicationStagesPath = '/api/lookup-values/application-stages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationStages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationStages$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationStageTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.GetApplicationStagesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationStageTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationStages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationStages(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ApplicationStageTypeDto>> {
    return this.getApplicationStages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationStageTypeDto>>): Array<ApplicationStageTypeDto> => r.body)
    );
  }

  /** Path part for operation `getApplicationBatchStages()` */
  static readonly GetApplicationBatchStagesPath = '/api/lookup-values/application-batch-stages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationBatchStages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationBatchStages$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationBatchStageTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.GetApplicationBatchStagesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationBatchStageTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationBatchStages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationBatchStages(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ApplicationBatchStageTypeDto>> {
    return this.getApplicationBatchStages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationBatchStageTypeDto>>): Array<ApplicationBatchStageTypeDto> => r.body)
    );
  }

}
