<header id="portal-banner-header">
  <h1 id="portal-home-banner-title-id" *ngIf="pageId === 'HOME'">
    Welcome to <abbr id="logo-label-abbr" title="{{ 'LGFF' | res: 'COMMON' }}">LGFF</abbr> Capital
  </h1>
  <h1 id="portal-resources-banner-title-id" *ngIf="pageId === 'RESOURCES'">
    <abbr id="logo-label-abbr" title="{{ 'LGFF' | res: 'COMMON' }}">LGFF</abbr> Resources
  </h1>
  <h1 id="portal-view-applications-banner-title-id" *ngIf="pageId === 'VIEW_APPLICATIONS'">
    <abbr id="logo-label-abbr" title="{{ 'LGFF' | res: 'COMMON' }}">LGFF</abbr> Capital applications
  </h1>
  <h1 id="portal-grant-application-banner-title-id" *ngIf="pageId === 'GRANT_APPLICATION'">
    <abbr id="logo-label-abbr" title="{{ 'LGFF' | res: 'COMMON' }}">LGFF</abbr> Capital application
  </h1>
  <h1 id="portal-allocations-payments-banner-title-id" *ngIf="pageId === 'ALLOCATIONS_PAYMENTS'">
    <abbr id="logo-label-abbr" title="{{ 'LGFF' | res: 'COMMON' }}">LGFF</abbr> Capital allocations and payments
  </h1>
</header>
<div id="headerunderline"></div>
