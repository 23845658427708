<goa-block mb="m" alignment="center" gap="s" class="lgff-pagination">
  <goa-block alignment="center" gap="s">
    <span>Page</span>
    <goa-dropdown relative="true" *ngIf="pageCount" [value]="pageNumber" (_change)="onPageChange($event)">
      <goa-dropdown-item *ngFor="let size of pageCount" value="{{ size }}"></goa-dropdown-item>
    </goa-dropdown>
    <span *ngIf="pageCount"> {{ 'of' }} {{ pageCount.length }}</span>
  </goa-block>

  <goa-block alignment="center" data-testid="page-links">
    <goa-button
      type="tertiary"
      leadingIcon="arrow-back"
      disabled="{{ pageNumber == 1 ? true : false }}"
      (_click)="onPreviousPage()"
      >Previous</goa-button
    >
    <goa-button
      type="tertiary"
      trailingIcon="arrow-forward"
      disabled="{{ pageNumber == pageCount.length || totalItemCount == 0 ? true : false }}"
      (_click)="onNextPage()"
      >Next</goa-button
    >
  </goa-block>
</goa-block>
