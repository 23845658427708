/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ContactDto } from '../models/contact-dto';
import { ContactFilter } from '../models/contact-filter';
import { OrganizationDto } from '../models/organization-dto';

@Injectable({ providedIn: 'root' })
export class ContactService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `count_2()` */
  static readonly Count_2Path = '/api/contacts/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(
    params?: {
      filter?: ContactFilter;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.Count_2Path, 'get');
    if (params) {
      rb.query('filter', params.filter, {"style":"deepObject","explode":true});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(
    params?: {
      filter?: ContactFilter;
    },
    context?: HttpContext
  ): Observable<number> {
    return this.count$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAll_3()` */
  static readonly FindAll_3Path = '/api/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll$Response(
    params?: {
      filter?: ContactFilter;
      take?: number;
      skip?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ContactDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.FindAll_3Path, 'get');
    if (params) {
      rb.query('filter', params.filter, {"style":"deepObject","explode":true});
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContactDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll(
    params?: {
      filter?: ContactFilter;
      take?: number;
      skip?: number;
    },
    context?: HttpContext
  ): Observable<Array<ContactDto>> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactDto>>): Array<ContactDto> => r.body)
    );
  }

  /** Path part for operation `findOne_3()` */
  static readonly FindOne_3Path = '/api/contacts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ContactDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.FindOne_3Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContactDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<Array<ContactDto>> {
    return this.findOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactDto>>): Array<ContactDto> => r.body)
    );
  }

  /** Path part for operation `findOrganizationByContactId()` */
  static readonly FindOrganizationByContactIdPath = '/api/contacts/{id}/organization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOrganizationByContactId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOrganizationByContactId$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OrganizationDto>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.FindOrganizationByContactIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrganizationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOrganizationByContactId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOrganizationByContactId(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<OrganizationDto> {
    return this.findOrganizationByContactId$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationDto>): OrganizationDto => r.body)
    );
  }

}
