import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';
import { BaseStepperComponent } from '../base-stepper/base-stepper.component';
import { Store } from '@ngxs/store';
import { ApplicationCertifiedByRole } from '@app-com/api/models/application-certified-by-role';
import { AuthStateService } from '@app-pot/shared/services/auth-state.service';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { ApplicationGrantState } from '@app-pot/store/state/application-draft.state';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.scss', '../common.scss'],
})
export class CertificationComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'CERTIFICATION';
  certificationObject: FormGroup;
  organizationIdSelected: number;
  organizationTitle: string | undefined;
  username: string;
  applicationDraftId?: number;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
    private authStateService: AuthStateService,
    private store: Store,
  ) {
    super();
    this.certificationObject = this.formBuilder.group({
      acceptanceRadio: ['', [Validators.required]],
    });

    this.store
      .select(CurrentContextState.getCurrentOrganizationId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentOrganizationId) => {
        this.organizationIdSelected = currentOrganizationId;
      });
    this.store
      .select(ApplicationGrantState.getApplicationDraftId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => (this.applicationDraftId = _));
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngOnInit(): void {
    this.authStateService.getAuthenticatedUser().subscribe((user) => {
      if (user) {
        this.username = user.name;
      }
      if (user?.roles) {
        this.organizationTitle = user.roles.find((x) => x.id == this.organizationIdSelected)?.title;
      }
    });
    this.certificationObject.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      console.log('Certification form values', value);
    });
  }

  getSelfAcceptance() {
    let selfAcceptance = this.res.transform('selfAcceptance', 'CERTIFICATION');
    if (selfAcceptance) {
      selfAcceptance = selfAcceptance.replace('%username%', this.username);
      selfAcceptance = selfAcceptance.replace('%organization%', this.organizationTitle!);
    }
    return selfAcceptance;
  }

  getRepresentativeAcceptance() {
    let representativeAcceptance = this.res.transform('representativeAcceptance', 'CERTIFICATION');
    if (representativeAcceptance) {
      representativeAcceptance = representativeAcceptance.replace('%username%', this.username);
      representativeAcceptance = representativeAcceptance.replace('%organization%', this.organizationTitle!);
    }
    return representativeAcceptance;
  }

  getApplicationCertifiedbyRoleSelection(): ApplicationCertifiedByRole {
    return this.certificationObject.controls['acceptanceRadio'].value;
  }

  public get acceptanceRadio(): typeof ApplicationCertifiedByRole {
    return ApplicationCertifiedByRole;
  }

  override validateOnPrevious(): boolean {
    return true;
  }

  override validateOnNext(): boolean {
    this.certificationObject.markAllAsTouched();
    if (!this.certificationObject.valid) {
      setTimeout(() => {
        this.jumpToField('certification-form-h3');
      }, 200);
    }
    return this.certificationObject.valid;
  }
}
