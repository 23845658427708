/* tslint:disable */
/* eslint-disable */
export enum PaymentStatusType {
  PendingEligibility = 'PendingEligibility',
  New = 'New',
  NewInBatch = 'NewInBatch',
  UnderReview = 'UnderReview',
  EoRecommendation = 'EORecommendation',
  AoApproval = 'AOApproval',
  PaymentInProgress = 'PaymentInProgress',
  PaidReconciled = 'PaidReconciled',
  ReconciliationError = 'ReconciliationError',
  OnHold = 'OnHold',
  Cancelled = 'Cancelled',
  Ineligible = 'Ineligible'
}
