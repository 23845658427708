import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';
import { TimeoutService } from './timeout.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-timeout-dialog',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss'],
})
export class TimeoutComponent implements OnInit, OnDestroy {
  _isOpen = false;
  @Input() set isOpen(value: boolean) {
    this._isOpen = value;
    this.inputChanged('isOpen');
  }

  _pageId = 'MODEL_IDLE_TIMEOUT';
  @Input() set pageId(value: string) {
    if (value && value.length > 0) this._pageId = value;
    this.inputChanged('pageId');
  }

  _titleMainParam = ''; // to replace %%
  @Input() set titleMainParam(param: string) {
    if (param && param.length > 0) this._titleMainParam = param;
    this.inputChanged('titleMainParam');
  }

  _titleSubParam = ''; // to replace %%
  @Input() set titleSubParam(param: string) {
    if (param && param.length > 0) this._titleSubParam = param;
    this.inputChanged('titleSubParam');
  }

  @Input() showBothOnRight = true;
  @Input() dlgMaxWidth = '496px';

  @Output() afterNoHandler = new EventEmitter<void>();
  @Output() afterYesHandler = new EventEmitter<void>();

  buttonNoText = '';
  buttonYesText = '';
  titleMainDef = '';
  titleSubDef = '';
  titleMainWantParam = false;
  titleSubWantParam = false;
  titleMainShow = '';
  titleSubShow = '';

  sub = new Subscription();

  constructor(
    public res: ResourcePipe,
    public timeoutService: TimeoutService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  inputChanged(changeType = '') {
    // console.log(changeType + ' pageId= ' + this._pageId + ', titleMainParam= ' + this._titleMainParam + ', isOpen= ' + this._isOpen);
    if (this._isOpen) {
      if (this.titleMainWantParam) {
        this.titleMainShow = this.titleMainDef.replace('"%%"', this._titleMainParam);
      }
      if (this.titleSubWantParam) {
        this.titleSubShow = this.titleSubDef.replace('"%%"', this._titleSubParam);
      }
    }
  }

  ngOnInit(): void {
    this.sub.add(
      this.timeoutService.getExpRemainTimeOutputStr().subscribe((dueTimeStr) => {
        this._titleSubParam = dueTimeStr;
        this.titleSubShow = this.titleSubDef.replace('"%%"', this._titleSubParam);
      }),
    );

    this.buttonNoText = this.res.transform('btnNo', this._pageId);
    this.buttonYesText = this.res.transform('btnYes', this._pageId);

    this.titleMainDef = this.res.transform('titleMain', this._pageId);
    if (this.titleMainDef.indexOf('"%%"') >= 0) {
      this.titleMainWantParam = true;
    } else {
      this.titleMainShow = this.titleMainDef;
    }

    this.titleSubDef = this.res.transform('titleSub', this._pageId);
    if (this.titleSubDef.indexOf('"%%"') >= 0) {
      this.titleSubWantParam = true;
    } else {
      this.titleSubShow = this.titleSubDef;
    }

    this.inputChanged('Confirm init');
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onNoClicked() {
    if (this._isOpen) {
      // prevent multiple-click
      this._isOpen = false;
      document.body.style.overflow = 'auto';
      this.afterNoHandler.emit();
    }
  }

  onYesClicked() {
    if (this._isOpen) {
      // prevent multiple-click
      this._isOpen = false;
      document.body.style.overflow = 'auto';
      this.afterYesHandler.emit();
    }
  }
}
