<div class="application-list-container">
  <goa-table id="table-container" width="100%" (_sort)="handleSort($event)">
    <thead>
      <tr>
        <th class="detail-icon-column"></th>
        <th class="minwidth-10rem">
          <goa-table-sort-header name="name">{{ 'name' | res: pageId }}</goa-table-sort-header>
        </th>
        <th class="minwidth-10rem">
          <goa-table-sort-header name="number">{{ 'number' | res: pageId }}</goa-table-sort-header>
        </th>
        <th class="minwidth-15rem">
          <goa-table-sort-header name="primaryFunctionalCategory">{{
            'primaryFunctionalCategory' | res: pageId
          }}</goa-table-sort-header>
        </th>
        <th class="minwidth-15rem">
          <goa-table-sort-header name="primaryOutcome">{{ 'primaryOutcome' | res: pageId }}</goa-table-sort-header>
        </th>
        <th class="minwidth-10rem">
          <goa-table-sort-header name="returnedDate">{{ 'returnedDate' | res: pageId }}</goa-table-sort-header>
        </th>
        <th class="minwidth-10rem">{{ 'actions' | res: pageId }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!pageApplications || pageApplications.length === 0">
        <td></td>
        <td colspan="6">
          <div class="font-italic pl-1rem">No results found in Returned</div>
        </td>
      </tr>
      <ng-container *ngFor="let application of pageApplications">
        <tr>
          <td>
            <goa-icon
              class="action-icon"
              [type]="application.showProjects ? 'chevron-down' : 'chevron-forward'"
              (click)="toggleProjectList(application.draftId)"
            ></goa-icon>
          </td>
          <td>{{ application.name }}</td>
          <td>{{ application.number }}</td>
          <td>{{ application.primaryFunctionalCategory }}</td>
          <td>{{ application.primaryOutcome }}</td>
          <td>{{ application.returnedDate }}</td>
          <td>
            <goa-block alignment="center" gap="l">
              <goa-tooltip content="Edit" position="bottom">
                <goa-icon class="action-icon" type="pencil" (click)="onEditClick(application.draftId)"></goa-icon
              ></goa-tooltip>
              <goa-tooltip content="Summary" position="bottom">
                <goa-icon
                  class="action-icon"
                  type="information-circle"
                  (click)="onAppInfoClick(application.draftId)"
                ></goa-icon
              ></goa-tooltip>
              <div class="action-download-tooltip-container">
                <goa-tooltip content="PDF" position="bottom">
                  <app-download-returned-application-pdf
                    class="action-icon"
                    [applicationId]="application.draftId"
                  ></app-download-returned-application-pdf
                ></goa-tooltip>
              </div>
              <goa-tooltip content="Withdraw" position="bottom">
                <goa-icon
                  class="action-icon"
                  type="return-down-back"
                  (click)="onDeleteClick(application.draftId)"
                ></goa-icon
              ></goa-tooltip>
            </goa-block>
          </td>
        </tr>
        <tr *ngIf="application.showProjects" class="projects-container">
          <td></td>
          <td colspan="6">
            <ng-container *ngIf="application.projects.length === 0; else projectList">
              <div>No Projects found for expanded row.</div>
            </ng-container>
            <ng-template #projectList>
              <table>
                <thead>
                  <tr>
                    <th>Project name</th>
                    <th>Primary capital asset</th>
                    <th>Additional capital asset</th>
                    <th class="numeric-column">LGFF funding requested</th>
                    <th>Anticipated start</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let project of application.projects">
                    <td>{{ project.name }}</td>
                    <td>{{ project.primaryCapitalAsset }}</td>
                    <td>{{ project.additionalCapitalAsset }}</td>
                    <td class="numeric-column">{{ project.lgffFundingRequested | safeDollar }}</td>
                    <td>{{ project.anticipatedStart }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </goa-table>
  <div class="application-list-pagination-container" *ngIf="filteredApplications.length" style="display: none">
    <goa-block alignment="center">
      <ng-container *ngIf="showPerPageControl">
        <goa-block mb="m" alignment="center">
          show
          <goa-dropdown
            (_change)="handlePerPageChange($event)"
            width="8ch"
            [value]="filteredApplications.length < 10 ? filteredApplications.length : '10'"
          >
            <goa-dropdown-item
              *ngIf="filteredApplications.length < 10"
              [value]="filteredApplications.length"
            ></goa-dropdown-item>
            <goa-dropdown-item value="10" *ngIf="filteredApplications.length >= 10"></goa-dropdown-item>
            <goa-dropdown-item value="20" *ngIf="filteredApplications.length >= 20"></goa-dropdown-item>
            <goa-dropdown-item value="30" *ngIf="filteredApplications.length >= 30"></goa-dropdown-item>
          </goa-dropdown>
          <span style="white-space: nowrap">of {{ filteredApplications.length }} items</span>
        </goa-block>
      </ng-container>
      <goa-spacer hspacing="fill"></goa-spacer>
      <goa-pagination
        [itemcount]="filteredApplications.length"
        [perpagecount]="perPage"
        [pagenumber]="page"
        (_change)="handlePageChange($event)"
      ></goa-pagination>
    </goa-block>
  </div>
</div>

<app-confirm-cancel-delete-draft-app
  pageId="MODEL_CANCEL_WITHDRAW_RETURNED_IN_LIST"
  [titleParam]="appNameToWithdraw"
  [showBothOnRight]="true"
  [isOpen]="openWithdrawModal"
  (afterNoHandler)="noWithdrawHandler()"
  (afterYesHandler)="yesWithdrawHandler()"
>
</app-confirm-cancel-delete-draft-app>
