<div>
  <div class="download-pdf-link">
    <app-download-submitted-applications-list-pdf
      linkText="Submitted applications report"
    ></app-download-submitted-applications-list-pdf>
  </div>
  <div class="application-list-container">
    <goa-table id="table-container" width="100%" (_sort)="handleSort($event)">
      <thead>
        <tr>
          <th class="detail-icon-column"></th>
          <th class="minwidth-10rem">
            <goa-table-sort-header name="name">{{ 'name' | res: pageId }}</goa-table-sort-header>
          </th>
          <th class="minwidth-10rem">
            <goa-table-sort-header name="appNumber">{{ 'appNumber' | res: pageId }}</goa-table-sort-header>
          </th>
          <th class="minwidth-15rem">
            <goa-table-sort-header name="primaryFunctionalCategoryTitle">
              {{ 'primaryFunctionalCategoryTitle' | res: pageId }}</goa-table-sort-header
            >
          </th>
          <th class="minwidth-10rem">
            <goa-table-sort-header name="primaryOutcomeTitle">
              {{ 'primaryOutcomeTitle' | res: pageId }}</goa-table-sort-header
            >
          </th>
          <th>
            <goa-table-sort-header name="status" direction="desc"> Status</goa-table-sort-header>
          </th>
          <th class="minwidth-10rem">
            <goa-table-sort-header name="submittedDate" direction="desc">
              {{ 'submittedDate' | res: pageId }}</goa-table-sort-header
            >
          </th>
          <th class="minwidth-10rem">{{ 'actions' | res: pageId }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!filteredApplications || filteredApplications.length === 0">
          <td></td>
          <td colspan="6">
            <div class="font-italic pl-1rem">No results found in Submitted</div>
          </td>
        </tr>
        <ng-container *ngFor="let application of filteredApplications">
          <tr>
            <td>
              <goa-icon
                class="action-icon"
                [type]="application.detailsShown ? 'chevron-down' : 'chevron-forward'"
                (click)="toggleApplicationDetails(application.id)"
              ></goa-icon>
            </td>

            <td>{{ application.name }}</td>
            <td>{{ application.appNumber }}</td>
            <td>{{ application.primaryFunctionalCategoryTitle }}</td>
            <td>{{ application.primaryOutcomeTitle }}</td>
            <td>
              <goa-badge type="information" content="Submitted" *ngIf="application.status == 'Submitted'"></goa-badge>
              <goa-badge type="important" content="In Review" *ngIf="application.status == 'InReview'"></goa-badge>
            </td>
            <td>{{ CommUtilsService.getDateStrMonDdYear(application.submittedDate ?? '') }}</td>
            <td>
              <goa-block alignment="center" gap="l">
                <goa-tooltip content="Summary" position="bottom">
                  <goa-icon
                    class="action-icon"
                    type="information-circle"
                    (click)="onAppInfoClick(application.id)"
                  ></goa-icon
                ></goa-tooltip>
                <div class="action-download-tooltip-container">
                  <goa-tooltip content="PDF" position="bottom">
                    <app-download-submitted-application-pdf
                      class="action-icon"
                      [applicationId]="application.id"
                    ></app-download-submitted-application-pdf
                  ></goa-tooltip>
                </div>
              </goa-block>
            </td>
          </tr>
          <tr *ngIf="application.detailsShown" class="projects-container">
            <td></td>
            <td colspan="7">
              <ng-container *ngIf="!application.projects || application.projects.length === 0; else projectList">
                <div class="font-italic pl-1rem">No Projects found for expanded row.</div>
              </ng-container>
              <ng-template #projectList>
                <table>
                  <thead>
                    <tr>
                      <th>Project name</th>
                      <th>Project number</th>
                      <th>Primary capital asset</th>
                      <th>Additional capital asset</th>
                      <th class="numeric-column">LGFF funding requested</th>
                      <th>Anticipated start</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let project of application.projects; index as ii">
                      <td>{{ project.name }}</td>
                      <td>{{ project.idTxt }}</td>
                      <td>{{ getCapitalAssetTypeTitle(project.capitalAssetTypeId) }}</td>
                      <td>{{ getAdditionalCapitalAssetTypeTitle(project) }}</td>
                      <td class="numeric-column">
                        {{ project.amountRequestedFromLGFF ?? 0 | safeDollar }}
                      </td>
                      <td>
                        {{ CommUtilsService.getDateStrMonDdYear(project.anticipatedStartDate ?? '') }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-template>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </goa-table>
  </div>
</div>
